import { useState, useEffect } from "react";
import * as authSlice from '../features/auth/auth.slice';
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { Avatar, Box, Button, Container, TextField, Typography } from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Copyright from "../components/copyright";
import { useNavigate } from "react-router-dom";
import { PasswordChangeRequest } from "../service/model/auth.model";

const ChangePassword = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const passwordChangeResponse = useAppSelector(authSlice.selectPasswordChangeResponse);

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    useEffect(() => {
        if (passwordChangeResponse && passwordChangeResponse.status === 'success') {
            dispatch(authSlice.resetState());
            navigate('/login');
        }
    }, [passwordChangeResponse]);

    const changePassword = async () => {
        const requestPasswordChange = async () => {
            const request: PasswordChangeRequest = { oldPassword: oldPassword ?? '', newPassword: newPassword }
            await dispatch(authSlice.changePassword(request));
        }

        await requestPasswordChange();
    }

    function handleOldPasswordChange(value: string): void {
        setOldPassword(value);
    }

    function handleNewPasswordChange(value: string): void {
        setNewPassword(value);
    }

    function isValid(): boolean {
       return oldPassword !== '' && newPassword !== '';
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5" sx={{ m: 3 }}>
                    Change your password
                </Typography>
                <Box sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="oldPassword"
                        label="Old Password"
                        type="password"
                        id="oldPassword"
                        onChange={e => handleOldPasswordChange(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="newPassword"
                        label="New Password"
                        type="password"
                        id="newPassword"
                        onChange={e => handleNewPasswordChange(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={changePassword}
                        disabled={!isValid()}
                    >
                        Change Password
                    </Button>
                </Box>
            </Box>
            <Box sx={{ mt: 8, mb: 4 }}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default ChangePassword;