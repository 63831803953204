import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "../features/auth/auth.slice";
import productSlice from "../features/products/product.slice";
import planSlice from "../features/plans/plan.slice";
import membershipSlice from "../features/memberships/membership.slice";
import attendanceSlice from "../features/attendance/attendance.slice";
import businessPaymentSlice from "../features/business-payments/business-payment.slice";
import memberPaymentSlice from "../features/member-payments/member-payment.slice";
import businessSlice from "../features/business/business.slice";
import memberSlice from "../features/members/member.slice";
import alertSlice from "../features/alerts/alert.slice";
import userDashboardSlice from "../features/dashboard/user-dashboard.slice";
import userSlice from "../features/user/user.slice";

const rootReducer = combineReducers({
    auth: authSlice,
    products: productSlice,
    plans: planSlice,
    memberships: membershipSlice,
    attendance: attendanceSlice,
    businessPayments: businessPaymentSlice,
    memberPayments: memberPaymentSlice,
    alerts: alertSlice,
    business: businessSlice,
    member: memberSlice,
    userDashboard: userDashboardSlice,
    users: userSlice
})

export default rootReducer;