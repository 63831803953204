import axios from "axios";
import { configs } from "../app/app-configs";
import { Business, BusinessSummary } from "./model/business.model";

export const getBusinesses = async (): Promise<Business[]> => {
    return await axios.get(`${configs.BASE_URL}/business`)
    .then(res => {
        return res.data as Business[];
    });
}

export const getBusiness = async (id: number): Promise<Business> => {
    return await axios.get(`${configs.BASE_URL}/business/${id}`)
    .then(res => {
        return res.data as Business;
    });
}

export const getBusinessSummary = async (): Promise<BusinessSummary> => {
    return await axios.get(`${configs.BASE_URL}/business/summary`)
    .then(res => {
        return res.data as BusinessSummary;
    });
}

export const createBusiness = async (request: Business): Promise<Business> => {
    return await axios.post(`${configs.BASE_URL}/business`, request)
    .then(res => {
        return res.data as Business;
    });
}

export const updateBusiness = async (request: Business): Promise<Business> => {
    return await axios.put(`${configs.BASE_URL}/business/${request.id}`, request)
    .then(res => {
        return res.data as Business;
    });
}

export const deleteBusiness = async (id: number): Promise<number> => {
    return await axios.delete(`${configs.BASE_URL}/business/${id}`)
    .then(res => {
        return res.data;
    });
}