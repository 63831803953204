import { Backdrop, CircularProgress } from "@mui/material";

const Loader = () => {
    // const [open, setOpen] = React.useState(true);

    // const handleClose = () => {
    //     setOpen(false);
    // };

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
                // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

export default Loader;