import { useAppSelector } from "../app/hooks";
import * as authSlice from "../features/auth/auth.slice";
import UserDashboard from "../features/dashboard/user-dashboard";
import { UserRoles } from "../shared/enums";

const Dashboard = () => {
    const permissions = useAppSelector(authSlice.selectPermissions);

    return (
        <div>
            {permissions.includes(UserRoles.Admin) &&
                <p>I am Admin</p>
            }
            {permissions.includes(UserRoles.User) &&
                <UserDashboard/>
            }
        </div>
    )
}

export default Dashboard;