import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Attendance } from "../../../service/model/attendance.model";
import { Box, Button, Dialog, DialogTitle, Grid, IconButton, TextField } from "@mui/material";
import FormField from "../../../components/form-controls/form-field";
import CloseIcon from '@mui/icons-material/Close';
import theme from "../../../app/app-theme";

interface AttendanceFormProps {
    readonly attendance?: Attendance
    readonly editable: boolean
    readonly onClose: () => void
    readonly onSubmit: (data: Attendance) => void
}

const style = {
    // position: 'absolute' as 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // width: 400,
    // bgcolor: 'background.paper',
    // border: '2px solid #000',
    // boxShadow: 24,
    p: 2,
};

const AttendanceForm: React.FC<AttendanceFormProps> = ({ attendance, editable, onClose, onSubmit }) => {
    const schema = yup.object().shape({
        date: yup.string().required()
    });

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmitHandler = (data: any) => {
        const updatedAttendance = {
            ...attendance,
            ...data
        }
        onSubmit(updatedAttendance as Attendance)
    };

    return (
        <Dialog open={true} onClose={onClose} sx={style}>
            <DialogTitle>Attendance Details</DialogTitle>
            <Box sx={style}>
                <>
                    <IconButton aria-label="close" onClick={onClose} sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}>
                        <CloseIcon />
                    </IconButton>
                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                        <FormField>
                            <TextField
                                id="name"
                                label="Name"
                                variant="outlined"
                                defaultValue={`${attendance?.firstName} ${attendance?.lastName}`}
                                disabled={true}
                            />
                        </FormField>
                        <FormField>
                            <TextField
                                id="date"
                                label="Date"
                                type="date"
                                variant="outlined"
                                defaultValue={attendance?.date ?? new Date().toISOString().slice(0, 10)}
                                error={errors.date !== undefined}
                                helperText={errors.date?.message}
                                {...register("date")}
                            />
                        </FormField>
                        <Grid container direction="row" justifyContent='end' sx={{ mt: 1 }}>
                            <Button type="button" variant="contained" sx={{ mr: 1, bgcolor: `${theme.palette.secondary.main}` }} onClick={onClose}>Cancel</Button>
                            <Button type="submit" variant="contained" >Submit</Button>
                        </Grid>
                    </form>
                </>
            </Box>
        </Dialog>
    );
}

export default AttendanceForm;