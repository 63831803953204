import { PayloadAction, createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import * as businessService from '../../service/business.service'
import { Business, BusinessSummary } from "../../service/model/business.model";
import { showNotification } from "../alerts/alert.slice";
import { NotificationType } from "../../shared/enums";

export interface BusinessState {
    isLoading: boolean;
    businesses: Business[];
    summary?: BusinessSummary;
}

const businessAdapter = createEntityAdapter({
    selectId: (business: Business) => business.id,
    sortComparer: (a, b) => a.name.localeCompare(b.name),
  })
  
export const initialState = businessAdapter.getInitialState({
    isLoading: false,
    businesses: [],
} as BusinessState);

export const fetchBusinessSummary = createAsyncThunk<BusinessSummary>(
    'businesses/summary',
    async (_, {rejectWithValue}) => {
        return businessService.getBusinessSummary().catch((errors) => {
            return rejectWithValue(errors);
        })
    }
);

export const fetchBusinesses = createAsyncThunk<Business[]>(
    'businesses/all',
    async (_, {rejectWithValue}) => {
        return businessService.getBusinesses().catch((errors) => {
            return rejectWithValue(errors);
        })
    }
);

export const createBusiness = createAsyncThunk<Business, Business>(
    'businesses/create',
    async (request, {rejectWithValue, dispatch}) => {
        return businessService.createBusiness(request)
        .then((response) => {
            dispatch(
                showNotification({
                    message: "Business created successfully",
                    type: NotificationType.Success,
                })
            );
            dispatch(fetchBusinessSummary());
            return response;
        }).catch((errors) => {
            dispatch(
                showNotification({
                    message: "Business creation failed",
                    type: NotificationType.Error,
                })
            );
            return rejectWithValue(errors);
        });
    }
);

export const updateBusiness = createAsyncThunk<Business, Business>(
    'businesses/update',
    async (request, {rejectWithValue, dispatch}) => {
        return businessService.updateBusiness(request)
        .then((response) => {
            dispatch(
                showNotification({
                    message: "Business updated successfully",
                    type: NotificationType.Success,
                })
            );
            dispatch(fetchBusinessSummary());
            return response;
        }).catch((errors) => {
            dispatch(
                showNotification({
                    message: "Business update failed",
                    type: NotificationType.Error,
                })
            );
            return rejectWithValue(errors);
        });
    }
);

export const deleteBusiness = createAsyncThunk<number, number>(
    'businesses/delete',
    async (request, {rejectWithValue, dispatch}) => {
        return businessService.deleteBusiness(request)
        .then((response) => {
            dispatch(
                showNotification({
                    message: "Business deleted successfully",
                    type: NotificationType.Success,
                })
            );
            dispatch(fetchBusinessSummary());
            return response;
        }).catch((errors) => {
            dispatch(
                showNotification({
                    message: "Business deletion failed",
                    type: NotificationType.Error,
                })
            );
            return rejectWithValue(errors);
        });
    }
);

const businessSlice = createSlice({
    name: 'business',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchBusinessSummary.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(fetchBusinessSummary.fulfilled, (state, action: PayloadAction<BusinessSummary>) => {
            state.summary = action.payload;
            state.isLoading = false;
        })
        .addCase(fetchBusinessSummary.rejected, (state) => {
            state.isLoading = false;
        })
        .addCase(fetchBusinesses.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(fetchBusinesses.fulfilled, (state, action: PayloadAction<Business[]>) => {
            businessAdapter.setAll(state, action.payload);
            state.isLoading = false;
        })
        .addCase(fetchBusinesses.rejected, (state) => {
            state.isLoading = false;
        })
        .addCase(createBusiness.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(createBusiness.fulfilled, (state, action: PayloadAction<Business>) => {
            businessAdapter.addOne(state, action.payload);
            state.isLoading = false;
        })
        .addCase(createBusiness.rejected, (state) => {
            state.isLoading = false;
        })
        .addCase(updateBusiness.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(updateBusiness.fulfilled, (state, action: PayloadAction<Business>) => {
            businessAdapter.upsertOne(state, action.payload);
            state.isLoading = false;
        })
        .addCase(updateBusiness.rejected, (state) => {
            state.isLoading = false;
        })
        .addCase(deleteBusiness.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(deleteBusiness.fulfilled, (state, action: PayloadAction<number>) => {
            businessAdapter.removeOne(state, action.payload);
            state.isLoading = false;
        })
        .addCase(deleteBusiness.rejected, (state) => {
            state.isLoading = false;
        });
    }
});

export const {
    selectAll: selectBusinesses,
    selectById: selectBusinessById
} = businessAdapter.getSelectors<RootState>((state) => state.business);

export const {} = businessSlice.actions;

export const selectIsLoading = (state: RootState) => state.business.isLoading;
export const selectBusinessSummary = (state: RootState) => state.business.summary;

export default businessSlice.reducer;