import useMemberPayments from "../hooks/use-member-payments";
import { makeStyles } from "@mui/styles";
import MemberPaymentForm from "../features/member-payments/components/member-payment-form";
import { useState } from "react";
import { MemberPayment as MemberPaymentModel } from "../service/model/member-payment.model";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import * as memberpaymentSlice from "../features/member-payments/member-payment.slice";
import Loader from "../components/loader";
import MemberPaymentSummary from "../features/member-payments/components/member-payment-summary";
import MemberPaymentTable from "../features/member-payments/components/member-payment-table";

const useStyles = makeStyles({
    createButton: {
        float: 'inline-end',
        margin: '12px 0 4px 12px'
    }
})

const MemberPayments = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const [showMemberPaymentForm, setShowMemberPaymentForm] = useState(false);
    const isLoading = useAppSelector(memberpaymentSlice.selectIsLoading);

    const [searchText, setSearchText] = useState('');

    // const getMemberPayments = async () => {
    //     await dispatch(memberpaymentSlice.fetchMemberPayments());
    // }

    // useEffect(() => {
    //     if (memberpayments.length === 0) {
    //         getMemberPayments();
    //     }
    // }, []);

    const [memberpayments] = useMemberPayments();

    const handleSubmit = (data: MemberPaymentModel) => {
        dispatch(memberpaymentSlice.createMemberPayment(data));
        setShowMemberPaymentForm(false);
    };

    // function setName(name: string) {
    //     setSearchText(name);
    // }

    const getFilteredMemberPayment = () => {
        if (searchText === '') {
            return memberpayments;
        }
        else {
            return memberpayments.filter(item => item.firstName.toLowerCase().includes(searchText.toLowerCase()));
        }
    }

    return (
        <div>
            {isLoading &&
                <Loader />
            }
            {memberpayments &&
                <>
                    <MemberPaymentSummary />
                    {/* <SearchBox placeholder="Search by name" setSearchValue={setName} /> */}
                    <MemberPaymentTable payments={getFilteredMemberPayment()} />
                    {showMemberPaymentForm &&
                        <MemberPaymentForm payment={undefined} editable={true} onClose={() => setShowMemberPaymentForm(false)} onSubmit={handleSubmit} />
                    }
                </>
            }
        </div>
    )
}

export default MemberPayments;