import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { fetchUserDashboardSummary, selectUserDashboardSummary } from "../features/dashboard/user-dashboard.slice";

const useUserDashboardSummary = () => {
    const dispatch = useAppDispatch();
    const summary = useAppSelector(selectUserDashboardSummary);

    const getUserDashboardSummary = async () => {
        await dispatch(fetchUserDashboardSummary());
    }

    useEffect(() => {
        getUserDashboardSummary();
    }, []);

    return summary;
}
 
export default useUserDashboardSummary;