import BusinessPaymentIcon from '@mui/icons-material/DirectionsWalk';
import useBusinessPaymentSummary from "../../../hooks/use-business-payment-summary";
import StatCard from "../../../components/StatCard";
import { StatSummary } from "../../../components/StatSummary";
import ChartCard from '../../../components/chart/chart-card';
import { Grid } from '@mui/material';
import DoughnutChart from '../../../components/chart/doughnut-chart';

const BusinessPaymentSummary = () => {
    const paymentSummary = useBusinessPaymentSummary();

    return (
        <div>
            {paymentSummary &&
                <>
                    <StatSummary>
                        <StatCard title="Today" value={paymentSummary?.todayTotal.toString()} icon={BusinessPaymentIcon} />
                        <StatCard title="This Week" value={paymentSummary?.currentWeekTotal.toString()} icon={BusinessPaymentIcon} />
                        <StatCard title="This Month" value={paymentSummary?.currentMonthTotal.toString()} icon={BusinessPaymentIcon} />
                    </StatSummary>
                    <Grid container direction="row" justifyContent="flex-start">
                        {/* <Grid item lg={3} md={4} sm={12} xs={12}>
                            <ChartCard><DoughnutChart data={businesspaymentSummary.countByPlan.map(item => { return { name: item.key, value: item.value } })} label='Count by Plan' /></ChartCard>
                        </Grid>
                        <Grid item lg={3} md={4} sm={12} xs={12}>
                            <ChartCard><DoughnutChart data={businesspaymentSummary.countByStatus.map(item => { return { name: item.key, value: item.value } })} label='Count by Status' /></ChartCard>
                        </Grid> */}
                        {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                            <ChartCard title='test'><LinesChart /></ChartCard>
                        </Grid> */}
                    </Grid>
                </>
            }
        </div>
    )
}

export default BusinessPaymentSummary;