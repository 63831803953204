import { Box, Grid, Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import aboutBackground from "../resources/images/home/background.jpg";
import heroImage from "../resources/images/home/hero-image.png";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        hero: {
            display: 'flex',
            minHeight: `100vh`,
            backgroundImage: `url(${aboutBackground})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: "white",
            // backgroundPosition: "center",
            [theme.breakpoints.down('sm')]: {
                backgroundPosition: "right",
            }
        },
        textHeading: {
            color: `white`,
            fontSize: '64px',
            fontWeight: 'bold',
            textAlign: 'center'
        },
        textDesc: {
            color: `white`,
            fontSize: '24px',
            lineHeight: '1.5',
            textAlign: 'center'
        },
    }
    ));

const Hero = () => {
    const classes = useStyles();

    return (
        //     <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.hero}>
        //   <Grid item lg={12} md={12} sm={12} xs={12} style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
        //     <div className="text-center">
        //       <h1 className={classes.textHeading}>Minimize Effort, Maximize Efficiency</h1>
        //       <h4 className={classes.textDesc}>Fitness center management software for increased organization and efficiency</h4>
        //     </div>
        //     <div className="text-center" style={{ marginTop: '2rem' }}>
        //     </div>
        //   </Grid>
        // </Grid>
        <Box sx={{ minHeight: '100vh', p: 4, display: 'flex', flexDirection: 'column', rowGap: 4 }} className={classes.hero}>
            <Grid container spacing={{ xs: 1, md: 2 }}
                sx={{ flexGrow: 1 }}>
                <>
                    <Grid sx={{ flexGrow: 1, position: 'relative', top: -60 }} item container md={6} xs={12} justifyContent="center" alignItems="center">
                        <div className="text-center">
                            <h1 className={classes.textHeading}>Minimize Effort, Maximize Efficiency</h1>
                            <h4 className={classes.textDesc}>Fitness center management software for increased organization and efficiency</h4>
                        </div>

                    </Grid>
                    <Grid sx={{ flexGrow: 1, top: -30 }} item container md={6} xs={12} justifyContent="start" alignItems="start">
                        <img src={heroImage} style={{maxWidth: "100%"}} alt=""/>
                    </Grid>
                </>
            </Grid>
        </Box>
    );
}

export default Hero;