import { RouterProvider } from "react-router-dom";
import './App.css';
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { getLoggedInUser, selectIsAuthenticated, setIsAuthenticated } from "./features/auth/auth.slice";
import { router } from "./app/app-routes";
import NotificationBar from "./features/alerts/notification-bar";

function App() {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      dispatch(setIsAuthenticated(true));
    } else {
      dispatch(setIsAuthenticated(false));
    }
  })

  useEffect(() => {
    if (isAuthenticated) {
    const fetchLoggedInUser = async () => {
            await dispatch(getLoggedInUser())
        }
        fetchLoggedInUser();
    }
}, [isAuthenticated]);


  return (
    <>
      <NotificationBar />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
