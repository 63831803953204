import { PayloadAction, createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import * as membershipService from '../../service/membership.service'
import { Membership } from "../../service/model/membership.model";
import { showNotification } from "../alerts/alert.slice";
import { NotificationType } from "../../shared/enums";
import { getAxiosErrorMessage } from "../../service/axios/axios.call.service";
import { PaginatedResponse } from "../../service/model/paginated-response";

export interface MembershipState {
    isLoading: boolean;
    memberships: Membership[];
    page?: PaginatedResponse<Membership>;
    totalPages: number;
    currentPage: number;
    totalRecords: number;
}

const membershipsAdapter = createEntityAdapter({
    selectId: (membership: Membership) => membership.id,
    sortComparer: (a, b) => a.name?.localeCompare(b.name),
  })
  
export const initialState = membershipsAdapter.getInitialState({
    isLoading: false,
    memberships: [],
    totalPages: 0,
    currentPage: 0,
    totalRecords: 0,
} as MembershipState);

export const fetchMemberships = createAsyncThunk<Membership[]>(
    'memberships/all',
    async (_, {rejectWithValue}) => {
        return membershipService.getMemberships().catch((errors) => {
            return rejectWithValue(errors);
        })
    }
);

// export const fetchMembershipsPage = createAsyncThunk<
//     PaginatedResponse<Membership>,
//     MembershipSearchCriteria
// >('memberships/page', async (pagination, { rejectWithValue }) => {
//     return membershipService
//         .getMembershipPage(pagination)
//         .catch((errors) => {
//             return rejectWithValue(errors);
//         });
// });

export const createMembership = createAsyncThunk<Membership, Membership>(
    'memberships/create',
    async (request, {rejectWithValue, dispatch}) => {
        return membershipService.createMembership(request)
        .then((response) => {
            dispatch(
                showNotification({
                    message: "Membership created successfully",
                    type: NotificationType.Success,
                })
            );
            // dispatch(
            //     fetchMembershipsPage(getDefaultMembershipSearchCriteria())
            // );
            return response;
        }).catch((errors) => {
            dispatch(
                showNotification({
                    message: getAxiosErrorMessage(errors.response),
                    type: NotificationType.Error,
                })
            );
            return rejectWithValue(errors);
        });
    }
);

export const updateMembership = createAsyncThunk<Membership, Membership>(
    'memberships/update',
    async (request, {rejectWithValue, dispatch}) => {
        return membershipService.updateMembership(request)
        .then((response) => {
            dispatch(
                showNotification({
                    message: "Membership updated successfully",
                    type: NotificationType.Success,
                })
            );
            return response;
        }).catch((errors) => {
            dispatch(
                showNotification({
                    message: getAxiosErrorMessage(errors.response),
                    type: NotificationType.Error,
                })
            );
            return rejectWithValue(errors);
        });
    }
);

export const deleteMembership = createAsyncThunk<number, number>(
    'memberships/delete',
    async (request, {rejectWithValue, dispatch}) => {
        return membershipService.deleteMembership(request)
        .then((response) => {
            dispatch(
                showNotification({
                    message: "Membership deleted successfully",
                    type: NotificationType.Success,
                })
            );
            return response;
        }).catch((errors) => {
            dispatch(
                showNotification({
                    message: getAxiosErrorMessage(errors.response),
                    type: NotificationType.Error,
                })
            );
            return rejectWithValue(errors);
        });
    }
);

const membershipSlice = createSlice({
    name: 'memberships',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchMemberships.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(fetchMemberships.fulfilled, (state, action: PayloadAction<Membership[]>) => {
            membershipsAdapter.setAll(state, action.payload);
            // state.currentPage = action.payload.currentPage;
            // state.totalPages = action.payload.totalPages;
            // state.totalRecords = action.payload.totalRecords;
            state.isLoading = false;
        })
        .addCase(fetchMemberships.rejected, (state) => {
            state.isLoading = false;
        })
        .addCase(createMembership.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(createMembership.fulfilled, (state, action: PayloadAction<Membership>) => {
            membershipsAdapter.addOne(state, action.payload);
            state.isLoading = false;
        })
        .addCase(createMembership.rejected, (state) => {
            state.isLoading = false;
        })
        .addCase(updateMembership.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(updateMembership.fulfilled, (state, action: PayloadAction<Membership>) => {
            membershipsAdapter.upsertOne(state, action.payload);
            state.isLoading = false;
        })
        .addCase(updateMembership.rejected, (state) => {
            state.isLoading = false;
        })
        .addCase(deleteMembership.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(deleteMembership.fulfilled, (state, action: PayloadAction<number>) => {
            membershipsAdapter.removeOne(state, action.payload);
            state.isLoading = false;
        })
        .addCase(deleteMembership.rejected, (state) => {
            state.isLoading = false;
        });
    }
});

export const {
    selectAll: selectMemberships,
    selectById: selectMembershipById
} = membershipsAdapter.getSelectors<RootState>((state) => state.memberships);

export const {} = membershipSlice.actions;

export const selectIsLoading = (state: RootState) => state.memberships.isLoading;

export default membershipSlice.reducer;