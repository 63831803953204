import { Box, IconButton, TableCell } from "@mui/material";
import StyledTableRow from "../../../components/table/styled-table-row";
import { Plan } from "../../../service/model/plan.model";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from "react";
import PlanForm from "./plan-form";
import { ConfirmationDialog } from "../../../components/confiramation-dialog";
import theme from "../../../app/app-theme";
import { useAppDispatch } from "../../../app/hooks";
import { deletePlan, updatePlan } from "../plan.slice";

interface TableRowProps {
    readonly plan: Plan
}

const PlanTableRow: React.FC<TableRowProps> = ({ plan }) => {
    const dispatch = useAppDispatch();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showPlanForm, setShowPlanForm] = useState(false);

    const handleDelete = () => {
        dispatch(deletePlan(plan.id))
        setShowDeleteDialog(false)
    }

    const handleDeleteCancel = () => {
        setShowDeleteDialog(false)
    }

    const handleSubmit = (data: Plan) => {
        dispatch(updatePlan({...data, id: plan.id}));
        setShowPlanForm(false);
    };

    return (
        <>
            <StyledTableRow key={plan.id}>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">ID</Box>{plan.id}</TableCell>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Name</Box>{plan.name}</TableCell>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Price</Box>{plan.price}</TableCell>
                {/* <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Expiry</Box>{node.branch.createdDate}</TableCell> */}
                <TableCell>
                    <Box component="div" display="inline" className="responsive-mobile-heading">Actions</Box>
                    {/* <IconButton onClick={() => setShowPlanForm(true)} sx={{color: `${theme.palette.secondary.main}`, paddingLeft: 0}}><VisibilityIcon /></IconButton> */}
                    <IconButton onClick={() => setShowPlanForm(true)} sx={{color: `${theme.palette.secondary.main}`}} className="table-action-button"><EditIcon /></IconButton>
                    <IconButton onClick={() => setShowDeleteDialog(true)} sx={{color: `${theme.palette.secondary.main}`}} className="table-action-button"><DeleteIcon /></IconButton>
                    {/* <Link className="btn" to="#" data-toggle="modal" data-target="#delete-member-modal" onClick={() => setShowDeleteDialog(true)}><DeleteIcon /></Link> */}
                </TableCell>
            </StyledTableRow>
            {showPlanForm &&
                <PlanForm plan={plan} editable={true} onClose={() => setShowPlanForm(false)} onSubmit={handleSubmit}/>
            }
            {showDeleteDialog &&
                <ConfirmationDialog show={true} message="Are you sure you want to delete?" onConfirm={handleDelete} onCancel={handleDeleteCancel}/>
            }
        </>
    );
}

export default PlanTableRow;