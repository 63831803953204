import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectPlans, fetchPlans } from "../features/plans/plan.slice";

const usePlans = () => {
    const dispatch = useAppDispatch();
    const plans = useAppSelector(selectPlans);

    const getPlans = async () => {
        await dispatch(fetchPlans());
    }

    useEffect(() => {
        if (plans.length === 0) {
            getPlans();
        }
    }, []);

    return [plans];
}
 
export default usePlans;