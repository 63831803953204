import useBusinessPayments from "../hooks/use-business-payments";
import { makeStyles } from "@mui/styles";
import BusinessPaymentForm from "../features/business-payments/components/business-payment-form";
import { useState } from "react";
import { BusinessPayment as BusinessPaymentModel } from "../service/model/business-payment.model";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import * as businesspaymentSlice from "../features/business-payments/business-payment.slice";
import Loader from "../components/loader";
import BusinessPaymentSummary from "../features/business-payments/components/business-payment-summary";
import BusinessPaymentTable from "../features/business-payments/components/business-payment-table";
import SearchBox from "../components/search-box";

const useStyles = makeStyles({
    createButton: {
        float: 'inline-end',
        margin: '12px 0 4px 12px'
    }
})

const BusinessPayments = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const [showBusinessPaymentForm, setShowBusinessPaymentForm] = useState(false);
    const isLoading = useAppSelector(businesspaymentSlice.selectIsLoading);

    const [searchText, setSearchText] = useState('');

    // const getBusinessPayments = async () => {
    //     await dispatch(businesspaymentSlice.fetchBusinessPayments());
    // }

    // useEffect(() => {
    //     if (businesspayments.length === 0) {
    //         getBusinessPayments();
    //     }
    // }, []);

    const [businesspayments] = useBusinessPayments();

    const handleSubmit = (data: BusinessPaymentModel) => {
        dispatch(businesspaymentSlice.createBusinessPayment(data));
        setShowBusinessPaymentForm(false);
    };

    function setName(name: string) {
        setSearchText(name);
    }

    const getFilteredBusinessPayment = () => {
        if (searchText === '') {
            return businesspayments;
        }
        else {
            return businesspayments.filter(item => item.businessName.toLowerCase().includes(searchText.toLowerCase()));
        }
    }

    return (
        <div>
            {isLoading &&
                <Loader />
            }
            {businesspayments &&
                <>
                    <BusinessPaymentSummary />
                    {/* <SearchBox placeholder="Search by name" setSearchValue={setName} /> */}
                    <BusinessPaymentTable payments={getFilteredBusinessPayment()} />
                    {showBusinessPaymentForm &&
                        <BusinessPaymentForm payment={undefined} editable={true} onClose={() => setShowBusinessPaymentForm(false)} onSubmit={handleSubmit} />
                    }
                </>
            }
        </div>
    )
}

export default BusinessPayments;