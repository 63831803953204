import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Plan } from "../../../service/model/plan.model";
import { Box, Button, Dialog, DialogTitle, Grid, IconButton, Modal, TextField } from "@mui/material";
import FormField from "../../../components/form-controls/form-field";
import CloseIcon from '@mui/icons-material/Close';
import theme from "../../../app/app-theme";

interface PlanFormProps {
    readonly plan?: Plan
    readonly editable: boolean
    readonly onClose: () => void
    readonly onSubmit: (data: Plan) => void
}

const style = {
    // position: 'absolute' as 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // width: 400,
    // bgcolor: 'background.paper',
    // border: '2px solid #000',
    // boxShadow: 24,
    p: 2,
};

const PlanForm: React.FC<PlanFormProps> = ({ plan, editable, onClose, onSubmit }) => {
    const schema = yup.object().shape({
        name: yup.string()
            .required(),
        price: yup.number()
            .transform((value) => Number.isNaN(value) ? null : value)
            .required("Price is required")
            .min(0)
    });

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmitHandler = (data: any) => {
        onSubmit(data as Plan)
    };

    return (
        <Dialog open={true} onClose={onClose} sx={style}>
            <DialogTitle>Plan Details</DialogTitle>
            <Box sx={style}>
                <>
                    <IconButton aria-label="close" onClick={onClose} sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}>
                        <CloseIcon />
                    </IconButton>
                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                        <FormField>
                            <TextField
                                id="plan-name"
                                label="Plan Name"
                                variant="outlined"
                                defaultValue={plan?.name}
                                error={errors.name !== undefined}
                                helperText={errors.name?.message}
                                {...register("name")}
                            />
                        </FormField>
                        <FormField>
                            <TextField
                                id="plan-price"
                                type="number"
                                label="Price"
                                variant="outlined"
                                defaultValue={plan?.price}
                                error={errors.price !== undefined}
                                helperText={errors.price?.message}
                                {...register("price")}
                            />
                        </FormField>
                        <Grid container direction="row" justifyContent='end' sx={{ mt: 1 }}>
                            <Button type="button" variant="contained" sx={{ mr: 1, bgcolor: `${theme.palette.secondary.main}` }} onClick={onClose}>Cancel</Button>
                            <Button type="submit" variant="contained" >Submit</Button>
                        </Grid>
                    </form>
                </>
            </Box>
        </Dialog>
    );
}

export default PlanForm;