import { UseFormRegisterReturn, useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { MemberPayment } from "../../../service/model/member-payment.model";
import { Box, Button, Dialog, DialogTitle, Grid, IconButton, TextField } from "@mui/material";
import FormField from "../../../components/form-controls/form-field";
import CloseIcon from '@mui/icons-material/Close';
import theme from "../../../app/app-theme";
import { TextInput } from "../../../components/form-input-field-wrappers/text-input";
import { DropdownInput } from "../../../components/form-input-field-wrappers/dropdown-input";
import useMemberships from "../../../hooks/use-memberships";
import { useEffect } from "react";

interface MemberPaymentFormProps {
    readonly payment?: MemberPayment
    readonly editable: boolean
    readonly onClose: () => void
    readonly onSubmit: (data: MemberPayment) => void
}

const style = {
    // position: 'absolute' as 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // width: 400,
    // bgcolor: 'background.paper',
    // border: '2px solid #000',
    // boxShadow: 24,
    p: 2,
};

const validationSchema = yup.object().shape({
    amount: yup.number().transform((value) => Number.isNaN(value) ? null : value).required("Amount is required"),
    membershipId: yup.number().transform((value) => Number.isNaN(value) ? null : value ).required("Membership is required"),
    paymentDate: yup.string().required("Payment date is required"),
    fromDate: yup.string().required("From date is required")
});

export type FormValues = yup.InferType<typeof validationSchema>;

const MemberPaymentForm: React.FC<MemberPaymentFormProps> = ({ payment, editable, onClose, onSubmit }) => {
    const [memberships] = useMemberships();

    const { register, watch, setValue, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const selectedMembership = watch("membershipId");

    useEffect(() => {
        setValue("amount", memberships.find(membership => membership.id === selectedMembership)?.amount ?? payment?.amount ?? 0);
    }, [selectedMembership]);
    
    const onSubmitHandler = (data: any) => {
        const updatedMemberPayment = {
            ...payment,
            ...data
        }
        onSubmit(updatedMemberPayment as MemberPayment)
    };

    const getRegister = (name: keyof FormValues, validation?: any): UseFormRegisterReturn => {
        return register(name, validation);
    };

    return (
        <Dialog open={true} onClose={onClose} sx={style}>
            <DialogTitle>Payment Details</DialogTitle>
            <Box sx={style}>
                <>
                    <IconButton aria-label="close" onClick={onClose} sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}>
                        <CloseIcon />
                    </IconButton>
                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                        <FormField>
                            <TextField
                                id="member"
                                label="Member"
                                variant="outlined"
                                defaultValue={`${payment?.firstName} ${payment?.lastName}`}
                                disabled={true}
                            />
                        </FormField>
                        <FormField>
                            <DropdownInput
                                id={"membership"}
                                label="Membership"
                                defaultValue={payment?.membershipId}
                                error={errors.membershipId !== undefined}
                                helperText={errors.membershipId?.message}
                                options={memberships.map(membership => { return { label: membership.name, value: membership.id } })}
                                register={register("membershipId")}
                            />
                        </FormField>
                        <FormField>
                            <TextField
                                id="amount"  
                                label="Amount"
                                variant="outlined"
                                defaultValue={payment?.amount}
                                error={errors.amount !== undefined}
                                helperText={errors.amount?.message}
                                {...register("amount")}
                            />
                        </FormField>
                        <FormField>
                            <TextInput
                                id="paymentDate"
                                label="Payment Date"
                                type="date"
                                variant="outlined"
                                defaultValue={payment?.paymentDate ?? new Date().toISOString().slice(0, 10)}
                                error={errors.paymentDate !== undefined}
                                helperText={errors.paymentDate?.message}
                                register={register("paymentDate")}
                            />
                        </FormField>
                        <FormField>
                            <TextInput
                                id="fromDate"
                                label="From Date"
                                type="date"
                                variant="outlined"
                                defaultValue={payment?.fromDate ?? new Date().toISOString().slice(0, 10)}
                                error={errors.fromDate !== undefined}
                                helperText={errors.fromDate?.message}
                                register={register("fromDate")}
                            />
                        </FormField>
                        <Grid container direction="row" justifyContent='end' sx={{ mt: 1 }}>
                            <Button type="button" variant="contained" sx={{ mr: 1, bgcolor: `${theme.palette.secondary.main}` }} onClick={onClose}>Cancel</Button>
                            <Button type="submit" variant="contained" >Submit</Button>
                        </Grid>
                    </form>
                </>
            </Box>
        </Dialog>
    );
}

export default MemberPaymentForm;