import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { fetchBusinessSummary, selectBusinessSummary } from "../features/business/business.slice";

const useBusinessSummary = () => {
    const dispatch = useAppDispatch();
    const summary = useAppSelector(selectBusinessSummary);

    const getBusinessSummary = async () => {
        await dispatch(fetchBusinessSummary());
    }

    useEffect(() => {
        getBusinessSummary();
    }, []);

    return summary;
}
 
export default useBusinessSummary;