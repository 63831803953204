import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { fetchMemberSummary, selectMemberSummary } from "../features/members/member.slice";

const useMemberSummary = () => {
    const dispatch = useAppDispatch();
    const summary = useAppSelector(selectMemberSummary);

    const getMemberSummary = async () => {
        await dispatch(fetchMemberSummary());
    }

    useEffect(() => {
        getMemberSummary();
    }, []);

    return summary;
}
 
export default useMemberSummary;