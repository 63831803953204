import { useEffect, useState } from "react";
import StyledTable, { ColumnHeader } from "../../../components/table/styled-table";
import BusinessPaymentTableRow from "./business-payment-table-row";
import { BusinessPayment, getDefaultBusinessPaymentSearchCriteria } from "../../../service/model/business-payment.model";
import { configs } from "../../../app/app-configs";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { fetchBusinessPaymentsPage, selectTotalPages } from "../business-payment.slice";
import SearchBox from "../../../components/search-box";
import DateRangeInput from "../../../components/form-input-field-wrappers/date-range-input";
import Grid from "@mui/material/Grid";

const headers: ColumnHeader[] = [
    { name: "Business" },
    { name: "Amount" },
    { name: "Payment Date" },
    { name: "Actions", className: "action-header" }
]

interface TableProps {
    readonly payments: BusinessPayment[]
}

const BusinessPaymentTable: React.FC<TableProps> = ({ payments }) => {
    const dispatch = useAppDispatch();
    const totalPages = useAppSelector(selectTotalPages);
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState('');

    const [dateRange, setDateRange] = useState({
        startDate: new Date(getDefaultBusinessPaymentSearchCriteria().from),
        endDate: new Date(getDefaultBusinessPaymentSearchCriteria().to),
      });

    function setName(name: string) {
        setSearchText(name);
    }

    useEffect(() => {
        dispatch(fetchBusinessPaymentsPage({ 
            pagination: { page: page - 1, size: configs.PAGE_SIZE }, 
            name: searchText, 
            from: dateRange.startDate.toISOString().slice(0, 10), 
            to: dateRange.endDate.toISOString().slice(0, 10) 
        }))
    }, [page, searchText, dateRange]);

    const handlePageChange = (selectedPage: number) => {
        setPage(selectedPage);
    }

    const handleDateChange = (start: Date | null, end: Date | null) => {
        setDateRange({ startDate: start ?? new Date(), endDate: end ?? new Date() });
      };

    return (
        <>
            <Grid container direction="row" style={{marginTop: '12px'}}>
                <Grid item lg={6} md={6} sm={12} sx={{ width: "100%" }}>
                    <SearchBox placeholder="Search by name" setSearchValue={setName} />
                </Grid>

                <DateRangeInput
                    initialStartDate={dateRange.startDate}
                    initialEndDate={dateRange.endDate}
                    onDateChange={handleDateChange}
                />
            </Grid>
            <StyledTable headers={headers}
                currentPage={page}
                totalPages={totalPages}
                onPageChange={handlePageChange}>

                {payments?.map(payment =>
                    <BusinessPaymentTableRow payment={payment} key={payment.id} />
                )}
            </StyledTable>
        </>
    );
}

export default BusinessPaymentTable;