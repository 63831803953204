import { Pagination } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            '& .MuiPaginationItem-root': {
                marginTop: '1rem',
                padding: 0,
                float: 'line-end'
            },
            '& .MuiPagination-ul': {
                float: 'inline-end'
            }
        }
    }
    )
)

interface PaginationProps {
    readonly currentPage: number,
    readonly totalPages: number,
    readonly onPageChange: (page: number) => void
}

export default function StyledPagination({ currentPage, totalPages, onPageChange }: PaginationProps) {
    const classes = useStyles();

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        onPageChange(value);
    };

    return (
        <Pagination className={`pagination justify-content-end mt-3 mb-0 ${classes.root}`} color="primary"
            count={totalPages}
            defaultPage={currentPage}
            siblingCount={1}
            boundaryCount={1}
            onChange={handleChange} />
    );

}