import { Box, IconButton, TableCell } from "@mui/material";
import StyledTableRow from "../../../components/table/styled-table-row";
import { Membership } from "../../../service/model/membership.model";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from "react";
import MembershipForm from "./membership-form";
import { ConfirmationDialog } from "../../../components/confiramation-dialog";
import theme from "../../../app/app-theme";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { deleteMembership, updateMembership } from "../membership.slice";
import { selectLoggedInUser } from "../../auth/auth.slice";

interface TableRowProps {
    readonly membership: Membership
}

const MembershipTableRow: React.FC<TableRowProps> = ({ membership }) => {
    const dispatch = useAppDispatch();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showMembershipForm, setShowMembershipForm] = useState(false);
    const loggedInUser = useAppSelector(selectLoggedInUser);

    const handleDelete = () => {
        dispatch(deleteMembership(membership.id))
        setShowDeleteDialog(false)
    }

    const handleDeleteCancel = () => {
        setShowDeleteDialog(false)
    }

    const handleSubmit = (data: Membership) => {
        loggedInUser && dispatch(updateMembership({...data, id: membership.id, businessId: loggedInUser.businessId})).then(resp => {
            if(resp.meta.requestStatus === 'fulfilled') {
                setShowMembershipForm(false);
            }
        });
    };

    return (
        <>
            <StyledTableRow key={membership.id}>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Name</Box>{membership.name}</TableCell>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Amount</Box>{membership.amount}</TableCell>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Duration (Days)</Box>{membership.durationDays}</TableCell>
                {/* <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Expiry</Box>{node.branch.createdDate}</TableCell> */}
                <TableCell>
                    <Box component="div" display="inline" className="responsive-mobile-heading">Actions</Box>
                    {/* <IconButton onClick={() => setShowMembershipForm(true)} sx={{color: `${theme.palette.secondary.main}`, paddingLeft: 0}}><VisibilityIcon /></IconButton> */}
                    <IconButton onClick={() => setShowMembershipForm(true)} sx={{color: `${theme.palette.secondary.main}`}} className="table-action-button"><EditIcon /></IconButton>
                    <IconButton onClick={() => setShowDeleteDialog(true)} sx={{color: `${theme.palette.secondary.main}`}} className="table-action-button"><DeleteIcon /></IconButton>
                    {/* <Link className="btn" to="#" data-toggle="modal" data-target="#delete-member-modal" onClick={() => setShowDeleteDialog(true)}><DeleteIcon /></Link> */}
                </TableCell>
            </StyledTableRow>
            {showMembershipForm &&
                <MembershipForm membership={membership} editable={true} onClose={() => setShowMembershipForm(false)} onSubmit={handleSubmit}/>
            }
            {showDeleteDialog &&
                <ConfirmationDialog show={true} message="Are you sure you want to delete?" onConfirm={handleDelete} onCancel={handleDeleteCancel}/>
            }
        </>
    );
}

export default MembershipTableRow;