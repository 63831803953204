import { Box, Button } from "@mui/material";
import useBusinesss from "../hooks/use-businesses";
import { makeStyles } from "@mui/styles";
import BusinessForm from "../features/business/components/business-form";
import { useState } from "react";
import { Business as BusinessModel } from "../service/model/business.model";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import * as businessSlice from "../features/business/business.slice";
import Loader from "../components/loader";
import BusinessSummary from "../features/business/components/business-summary";
import BusinessTable from "../features/business/components/business-table";
import SearchBox from "../components/search-box";

const useStyles = makeStyles({
    createButton: {
        float: 'inline-end',
        margin: '12px 0 4px 12px'
    }
})

const Business = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const [showBusinessForm, setShowBusinessForm] = useState(false);
    const isLoading = useAppSelector(businessSlice.selectIsLoading);

    const [searchText, setSearchText] = useState('');

    // const getBusinesss = async () => {
    //     await dispatch(businessSlice.fetchBusinesss());
    // }

    // useEffect(() => {
    //     if (businesses.length === 0) {
    //         getBusinesss();
    //     }
    // }, []);

    const [businesses] = useBusinesss();

    const handleSubmit = (data: BusinessModel) => {
        dispatch(businessSlice.createBusiness(data));
        setShowBusinessForm(false);
    };

    function setName(name: string) {
        setSearchText(name);
    }

    const getFilteredBusiness = () => {
        if(searchText === '') {
            return businesses;
        } else {
            return businesses.filter(item => item.name.toLowerCase().includes(searchText.toLowerCase()));
        }
    }

    return (
        <div>
            {isLoading &&
                <Loader />
            }
            {businesses &&
                <>
                    <BusinessSummary />
                    <Box>
                        <Button variant="contained" className={classes.createButton} onClick={() => setShowBusinessForm(true)}>Create</Button>
                    </Box>
                    <SearchBox placeholder="Search by name" setSearchValue={setName} />
                    <BusinessTable businesses={getFilteredBusiness()} />
                    {/* <BusinessDataTable/> */}
                    {showBusinessForm &&
                        <BusinessForm business={undefined} editable={true} onClose={() => setShowBusinessForm(false)} onSubmit={handleSubmit} />
                    }
                </>
            }
        </div>
    )
}

export default Business;