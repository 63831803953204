import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectBusinesses, fetchBusinesses } from "../features/business/business.slice";

const useBusinesess = () => {
    const dispatch = useAppDispatch();
    const businesess = useAppSelector(selectBusinesses);

    const getBusinesess = async () => {
        await dispatch(fetchBusinesses());
    }

    useEffect(() => {
        if (businesess.length === 0) {
            getBusinesess();
        }
    }, []);

    return [businesess];
}
 
export default useBusinesess;