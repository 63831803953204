import { AxiosResponse } from 'axios';

// export interface ApiResponse<T = any> {
//   status?: number;
//   data?: T;
//   message?: string;
// }

// export const makeAxiosCall = async <T>(
//   endpoint: string,
//   method: Method = 'GET',
//   data: any = null,
//   headers: Record<string, string> = {}
// ) => {
//   try {
//     const response: AxiosResponse<T> = await axios({
//       url: endpoint,
//       method,
//       data,
//       headers,
//     });

//     return {
//       status: response.status,
//       data: response.data,
//     };
//   } catch (error: any) {
//     if (error.response) {
//       return {
//         status: error.response.status,
//         message: error.response.data || 'Something went wrong',
//       };
//     } else {
//       return {
//         status: 500,
//         message: 'Network Error',
//       };
//     }
//   }
// };

export const getAxiosErrorMessage = (error: AxiosResponse): string => {
  return error.data ?? 'Something went wrong';
}