import { useState, useEffect } from "react";
import * as authSlice from '../features/auth/auth.slice';
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { Avatar, Box, Button, Container, TextField, Typography } from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Copyright from "../components/copyright";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PasswordResetRequest } from "../service/model/auth.model";

const ResetPassword = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const passwordResetResponse = useAppSelector(authSlice.selectPasswordResetResponse);
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get('token');

    const [password, setPassword] = useState('');

    useEffect(() => {
        dispatch(authSlice.resetState());
    }, []);

    useEffect(() => {
        if (passwordResetResponse && passwordResetResponse.status === 'success') {
            navigate('/login');
        }
    }, [passwordResetResponse]);

    const resetPassword = async () => {
        const requestPasswordReset = async () => {
            const request: PasswordResetRequest = { token: token ?? '', password: password }
            await dispatch(authSlice.resetPassword(request));
        }

        await requestPasswordReset();
    }

    function handlePasswordChange(value: string): void {
        setPassword(value);
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5" sx={{ m: 3 }}>
                    Reset your password
                </Typography>
                <Box sx={{ mt: 1 }}>
                    <Typography>Enter your new password</Typography>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        onChange={e => handlePasswordChange(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={resetPassword}
                        disabled={password === ''}
                    >
                        Reset Password
                    </Button>
                </Box>
            </Box>
            <Box sx={{ mt: 8, mb: 4 }}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default ResetPassword;