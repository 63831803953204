import { Box, Chip, IconButton, TableCell } from "@mui/material";
import StyledTableRow from "../../../components/table/styled-table-row";
import { Business } from "../../../service/model/business.model";
import MoneyIcon from '@mui/icons-material/AttachMoney';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ReactNode, useState } from "react";
import BusinessForm from "./business-form";
import { ConfirmationDialog } from "../../../components/confiramation-dialog";
import theme from "../../../app/app-theme";
import { useAppDispatch } from "../../../app/hooks";
import { deleteBusiness, updateBusiness } from "../business.slice";
import usePlans from "../../../hooks/use-plans";
import BusinessPaymentForm from "../../business-payments/components/business-payment-form";
import { BusinessPayment } from "../../../service/model/business-payment.model";
import { createBusinessPayment } from "../../business-payments/business-payment.slice";

interface TableRowProps {
    readonly business: Business
}

type BusinessStatus = 'Trial' | 'Active' | 'Expired' | 'Deleted' | 'Suspended';

const BusinessTableRow: React.FC<TableRowProps> = ({ business }) => {
    const dispatch = useAppDispatch();
    const [plans] = usePlans();

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showBusinessForm, setShowBusinessForm] = useState(false);
    const [showPaymentForm, setShowPaymentForm] = useState(false);

    const handleDelete = () => {
        dispatch(deleteBusiness(business.id))
        setShowDeleteDialog(false)
    }

    const handleDeleteCancel = () => {
        setShowDeleteDialog(false)
    }

    const handleSubmit = (data: Business) => {
        dispatch(updateBusiness({ ...data, id: business.id }));
        setShowBusinessForm(false);
    };

    
    const handlePaymentSubmit = (data: BusinessPayment) => {
        dispatch(createBusinessPayment(data)).then(resp => {
            if (resp.meta.requestStatus === 'fulfilled') {
                setShowPaymentForm(false);
            }
        });
    };

    const getStatusChip = (status: BusinessStatus): ReactNode => {
        switch(status) { 
            case "Trial": { 
               return <Chip label={status} color="info"/> 
            } 
            case "Active": { 
                return <Chip label={status} color="success"/> 
            } 
            case "Suspended": { 
                return <Chip label={status} color="warning"/> 
             } 
             case "Expired": { 
                return <Chip label={status} color="error"/>  
             } 
             case "Deleted": { 
                return <Chip label={status} color="default"/> 
             } 
            default: { 
                return <Chip label={status} color="default"/> 
            } 
         }
    }

    return (
        <>
            <StyledTableRow key={business.id}>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">ID</Box>{business.id}</TableCell>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Name</Box>{business.name}</TableCell>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Plan</Box>{plans.find(plan => plan.id === business.planId)?.name}</TableCell>
                <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Status</Box>{getStatusChip(business.status as BusinessStatus)}</TableCell>
                {/* <TableCell><Box component="div" display="inline" className="responsive-mobile-heading">Expiry</Box>{node.branch.createdDate}</TableCell> */}
                <TableCell>
                    <Box component="div" display="inline" className="responsive-mobile-heading">Actions</Box>
                    {/* <IconButton onClick={() => setShowBusinessForm(true)} sx={{color: `${theme.palette.secondary.main}`, paddingLeft: 0}}><VisibilityIcon /></IconButton> */}
                    <IconButton onClick={() => setShowPaymentForm(true)} sx={{ color: `${theme.palette.secondary.main}` }} className="table-action-button"><MoneyIcon /></IconButton>
                    <IconButton onClick={() => setShowBusinessForm(true)} sx={{ color: `${theme.palette.secondary.main}` }} className="table-action-button"><EditIcon /></IconButton>
                    <IconButton onClick={() => setShowDeleteDialog(true)} sx={{ color: `${theme.palette.secondary.main}` }} className="table-action-button"><DeleteIcon /></IconButton>
                    {/* <Link className="btn" to="#" data-toggle="modal" data-target="#delete-member-modal" onClick={() => setShowDeleteDialog(true)}><DeleteIcon /></Link> */}
                </TableCell>
            </StyledTableRow>
            {showPaymentForm &&
                <BusinessPaymentForm payment={{businessId: business.id, businessName: business.name} as BusinessPayment} editable={true} onClose={() => setShowPaymentForm(false)} onSubmit={handlePaymentSubmit} />
            }
            {showBusinessForm &&
                <BusinessForm business={business} editable={true} onClose={() => setShowBusinessForm(false)} onSubmit={handleSubmit} />
            }
            {showDeleteDialog &&
                <ConfirmationDialog show={true} message={`Are you sure you want to delete ${business.name} ?`} onConfirm={handleDelete} onCancel={handleDeleteCancel} />
            }
        </>
    );
}

export default BusinessTableRow;