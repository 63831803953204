import { FormControl, InputLabel, Select, MenuItem, FormHelperText, SelectProps } from "@mui/material"
import { UseFormRegisterReturn } from "react-hook-form"

type OptionType = { label: string, value: string | number }

interface DropdownInputProps extends Omit<SelectProps, 'name' | 'onChange'> {
    readonly id?: string
    readonly name?: string
    readonly type?: string
    readonly className?: string
    readonly label?: string
    readonly defaultValue?: string | number
    readonly value?: string | number
    readonly disabled?: boolean
    readonly error?: boolean
    readonly helperText?: string
    readonly options: OptionType[]
    readonly variant?: "standard" | "filled" | "outlined"
    register: UseFormRegisterReturn;
}

export const DropdownInput: React.FC<DropdownInputProps> = ({ name, label, register, error, variant='outlined', options, ...props }) => {
    const { name: registerName} = register;

    return <FormControl className="w-100" error={error}>
        <InputLabel id={label}>{label}</InputLabel>
        <Select
            id={props.id}
            {...register}
            label={label}
            name={registerName}
            className="mb-3"
            labelId={label}
            variant={variant}
            disabled={props.disabled}
            defaultValue={props.defaultValue}
        >
            {
                options.map(option => <MenuItem key={option.label} value={option.value}>{option.label}</MenuItem>)
            }
        </Select>
        <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
}
