import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectAttendances, fetchAttendancesPage } from "../features/attendance/attendance.slice";
import { getDefaultAttendanceSearchCriteria } from "../service/model/attendance.model";

const useBusinesess = () => {
    const dispatch = useAppDispatch();
    const attendances = useAppSelector(selectAttendances);

    const getBusinesess = async () => {
        await dispatch(fetchAttendancesPage(getDefaultAttendanceSearchCriteria()));
    }

    useEffect(() => {
        if (attendances.length === 0) {
            getBusinesess();
        }
    }, []);

    return [attendances];
}
 
export default useBusinesess;