import { makeStyles } from "@mui/styles";
import theme from "../../app/app-theme";
import { PropsWithChildren } from "react";
import { TableContainer, Paper, TableHead, TableRow, TableCell, TableBody, Table, TextField } from "@mui/material";
import StyledPagination from "./styled-pagination";

export type ColumnHeader = {
    name: string;
    className?: string;
};

interface TableProps {
    readonly headers: ColumnHeader[],
    readonly currentPage: number,
    readonly totalPages: number,
    readonly onPageChange: (page: number) => void
}

const useStyles = makeStyles({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    thead: {
        '& .MuiTableCell-root': {
            background: `${theme.palette.primary.main} !important`,
            color: `${theme.palette.secondary.light} !important`
        }
    },
    tbody: {
        '& .MuiTableRow-root': {
            [theme.breakpoints.down('sm')]: {
                paddingBottom: '16px'
            }
        },
        '& .MuiTableCell-root': {
            paddingTop: '8px'
        },
        '& .MuiTableCell-root .btn': {
            padding: '0 1.6rem 0 0',
        },
        '& .responsive-mobile-heading': {
            display: 'inline-block',
            width: '40%',
            color: `${theme.palette.secondary.dark}`,
            fontWeight: 'bold',
            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        },
        '& .table-action-button': {
        }
    }
});

const StyledTable = ({ headers, currentPage, totalPages, onPageChange, children }: PropsWithChildren<TableProps>) => {
    const classes = useStyles();

    return (
        <div className="table-responsive mt-3 p-0">
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow className={classes.thead}>
                            {headers.map(header => <TableCell key={header.name} className={header.className}>{header.name}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tbody}>
                        {children}
                    </TableBody>
                </Table>
            </TableContainer>
            <StyledPagination currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
            />
        </div>
    );
}

export default StyledTable;