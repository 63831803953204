import { useEffect, useState } from "react";
import StyledTable, { ColumnHeader } from "../../../components/table/styled-table";
import AttendanceTableRow from "./attendance-table-row";
import { Attendance, getDefaultAttendanceSearchCriteria } from "../../../service/model/attendance.model";
import { configs } from "../../../app/app-configs";
import { Grid } from "@mui/material";
import SearchBox from "../../../components/search-box";
import DateRangeInput from "../../../components/form-input-field-wrappers/date-range-input";
import { fetchAttendancesPage, selectTotalPages } from "../attendance.slice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";

const headers: ColumnHeader[] = [
    { name: "First Name" },
    { name: "Last Name" },
    { name: "Date" },
    { name: "Time" },
    { name: "Actions", className: "action-header" }
]

interface TableProps {
    readonly attendances: Attendance[]
}

const AttendanceTable: React.FC<TableProps> = ({attendances}) => {
    const dispatch = useAppDispatch();
    const totalPages = useAppSelector(selectTotalPages);
    
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState('');

    const [dateRange, setDateRange] = useState({
        startDate: new Date(getDefaultAttendanceSearchCriteria().from),
        endDate: new Date(getDefaultAttendanceSearchCriteria().to),
    });
    
    useEffect(() => {
        dispatch(fetchAttendancesPage({
            pagination: { page: page - 1, size: configs.PAGE_SIZE },
            name: searchText,
            from: dateRange.startDate.toISOString().slice(0, 10),
            to: dateRange.endDate.toISOString().slice(0, 10)
        }))
    }, [page, searchText, dateRange]);
    
    const handlePageChange = (selectedPage: number) => {
        setPage(selectedPage);
    }

    function setName(name: string) {
        setSearchText(name);
    }

    const handleDateChange = (start: Date | null, end: Date | null) => {
        setDateRange({ startDate: start ?? new Date(), endDate: end ?? new Date() });
    };

    return (
        <>
            <Grid container direction="row" style={{ marginTop: '12px' }}>
                <Grid item lg={6} md={6} sm={12} sx={{ width: "100%" }}>
                    <SearchBox placeholder="Search by name" setSearchValue={setName} />
                </Grid>

                <DateRangeInput
                    initialStartDate={dateRange.startDate}
                    initialEndDate={dateRange.endDate}
                    onDateChange={handleDateChange}
                />
            </Grid>
            <StyledTable headers={headers}
                currentPage={page}
                totalPages={totalPages}
                onPageChange={handlePageChange}>

                {attendances?.map(attendance =>
                    <AttendanceTableRow attendance={attendance} key={attendance.id} />
                )}
            </StyledTable>
        </>
    );
}

export default AttendanceTable;