import BusinessIcon from '@mui/icons-material/Business';
import useBusinessSummary from "../../../hooks/use-businesse-summary";
import StatCard from "../../../components/StatCard";
import { StatSummary } from "../../../components/StatSummary";
import ChartCard from '../../../components/chart/chart-card';
import { Grid } from '@mui/material';
import DoughnutChart from '../../../components/chart/doughnut-chart';

const BusinessSummary = () => {
    const businessSummary = useBusinessSummary();

    return (
        <div>
            {businessSummary &&
                <>
                    <StatSummary>
                        <StatCard title="Total Count" value={businessSummary?.totalCount.toString()} icon={BusinessIcon} />
                        <StatCard title="Joined This Month" value={businessSummary?.currentMonthCount.toString()} icon={BusinessIcon} />
                        <StatCard title="Expired" value={businessSummary?.expiredCount.toString()} icon={BusinessIcon} />
                    </StatSummary>
                    <Grid container direction="row" justifyContent="flex-start">
                        <Grid item lg={3} md={4} sm={12} xs={12}>
                            <ChartCard><DoughnutChart data={businessSummary.countByPlan.map(item => { return { name: item.key, value: item.value } })} label='Count by Plan' /></ChartCard>
                        </Grid>
                        <Grid item lg={3} md={4} sm={12} xs={12}>
                            <ChartCard><DoughnutChart data={businessSummary.countByStatus.map(item => { return { name: item.key, value: item.value } })} label='Count by Status' /></ChartCard>
                        </Grid>
                        {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                            <ChartCard title='test'><LinesChart /></ChartCard>
                        </Grid> */}
                    </Grid>
                </>
            }
        </div>
    )
}

export default BusinessSummary;