import {
    PayloadAction,
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import * as memberPaymentService from '../../service/member-payment.service';
import {
    MemberPayment,
    MemberPaymentSearchCriteria,
    MemberPaymentSummary,
    getDefaultMemberPaymentSearchCriteria,
} from '../../service/model/member-payment.model';
import { showNotification } from '../alerts/alert.slice';
import { NotificationType } from '../../shared/enums';
import { getAxiosErrorMessage } from '../../service/axios/axios.call.service';
import { PaginatedResponse } from '../../service/model/paginated-response';
import { fetchMembers } from '../members/member.slice';

export interface MemberPaymentState {
    isLoading: boolean;
    payments: MemberPayment[];
    summary?: MemberPaymentSummary;
    page?: PaginatedResponse<MemberPayment>;
    totalPages: number;
    currentPage: number;
    totalRecords: number;
}

const memberPaymentAdapter = createEntityAdapter({
    selectId: (payment: MemberPayment) => payment.id,
    sortComparer: (a, b) => b.paymentDate?.localeCompare(a.paymentDate),
});

export const initialState = memberPaymentAdapter.getInitialState({
    isLoading: false,
    payments: [],
    totalPages: 0,
    currentPage: 0,
    totalRecords: 0,
} as MemberPaymentState);

export const fetchMemberPaymentSummary =
    createAsyncThunk<MemberPaymentSummary>(
        'memberPayments/summary',
        async (_, { rejectWithValue }) => {
            return memberPaymentService
                .getMemberPaymentSummary()
                .catch((errors) => {
                    return rejectWithValue(errors);
                });
        }
    );

// export const fetchMemberPayments = createAsyncThunk<MemberPayment[]>(
//     'memberPayments/all',
//     async (_, {rejectWithValue}) => {
//         return memberPaymentService.getMemberPayments().catch((errors) => {
//             return rejectWithValue(errors);
//         })
//     }
// );

export const fetchMemberPaymentsPage = createAsyncThunk<
    PaginatedResponse<MemberPayment>,
    MemberPaymentSearchCriteria
>('memberPayments/page', async (pagination, { rejectWithValue }) => {
    return memberPaymentService
        .getMemberPaymentsPage(pagination)
        .catch((errors) => {
            return rejectWithValue(errors);
        });
});

export const createMemberPayment = createAsyncThunk<
    MemberPayment,
    MemberPayment
>('memberPayments/create', async (request, { rejectWithValue, dispatch }) => {
    return memberPaymentService
        .createMemberPayment(request)
        .then((response) => {
            dispatch(
                showNotification({
                    message: 'MemberPayment created successfully',
                    type: NotificationType.Success,
                })
            );
            // dispatch(fetchMemberPayments())
            dispatch(
                fetchMemberPaymentsPage(getDefaultMemberPaymentSearchCriteria())
            );
            dispatch(fetchMembers());
            return response;
        })
        .catch((errors) => {
            dispatch(
                showNotification({
                    message: getAxiosErrorMessage(errors.response),
                    type: NotificationType.Error,
                })
            );
            return rejectWithValue(errors);
        });
});

export const updateMemberPayment = createAsyncThunk<
    MemberPayment,
    MemberPayment
>('memberPayments/update', async (request, { rejectWithValue, dispatch }) => {
    return memberPaymentService
        .updateMemberPayment(request)
        .then((response) => {
            dispatch(
                showNotification({
                    message: 'MemberPayment updated successfully',
                    type: NotificationType.Success,
                })
            );
            dispatch(fetchMemberPaymentSummary());
            return response;
        })
        .catch((errors) => {
            dispatch(
                showNotification({
                    message: getAxiosErrorMessage(errors.response),
                    type: NotificationType.Error,
                })
            );
            return rejectWithValue(errors);
        });
});

export const deleteMemberPayment = createAsyncThunk<number, number>(
    'memberPayments/delete',
    async (request, { rejectWithValue, dispatch }) => {
        return memberPaymentService
            .deleteMemberPayment(request)
            .then((response) => {
                dispatch(
                    showNotification({
                        message: 'Member payment deleted successfully',
                        type: NotificationType.Success,
                    })
                );
                dispatch(fetchMemberPaymentSummary());
                return response;
            })
            .catch((errors) => {
                dispatch(
                    showNotification({
                        message: getAxiosErrorMessage(errors.response),
                        type: NotificationType.Error,
                    })
                );
                return rejectWithValue(errors);
            });
    }
);

const memberPaymentSlice = createSlice({
    name: 'member-payment',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMemberPaymentSummary.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(
                fetchMemberPaymentSummary.fulfilled,
                (state, action: PayloadAction<MemberPaymentSummary>) => {
                    state.summary = action.payload;
                    state.isLoading = false;
                }
            )
            .addCase(fetchMemberPaymentSummary.rejected, (state) => {
                state.isLoading = false;
            })
            // .addCase(fetchMemberPayments.pending, (state) => {
            //     state.isLoading = true;
            // })
            // .addCase(fetchMemberPayments.fulfilled, (state, action: PayloadAction<MemberPayment[]>) => {
            //     memberPaymentAdapter.setAll(state, action.payload);
            //     state.isLoading = false;
            // })
            // .addCase(fetchMemberPayments.rejected, (state) => {
            //     state.isLoading = false;
            // })
            .addCase(fetchMemberPaymentsPage.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(
                fetchMemberPaymentsPage.fulfilled,
                (
                    state,
                    action: PayloadAction<PaginatedResponse<MemberPayment>>
                ) => {
                    memberPaymentAdapter.setAll(state, action.payload.data);
                    state.currentPage = action.payload.currentPage;
                    state.totalPages = action.payload.totalPages;
                    state.totalRecords = action.payload.totalRecords;
                    state.isLoading = false;
                }
            )
            .addCase(fetchMemberPaymentsPage.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(createMemberPayment.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(
                createMemberPayment.fulfilled,
                (state, action: PayloadAction<MemberPayment>) => {
                    memberPaymentAdapter.addOne(state, action.payload);
                    state.isLoading = false;
                }
            )
            .addCase(createMemberPayment.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(updateMemberPayment.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(
                updateMemberPayment.fulfilled,
                (state, action: PayloadAction<MemberPayment>) => {
                    memberPaymentAdapter.upsertOne(state, action.payload);
                    state.isLoading = false;
                }
            )
            .addCase(updateMemberPayment.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(deleteMemberPayment.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(
                deleteMemberPayment.fulfilled,
                (state, action: PayloadAction<number>) => {
                    memberPaymentAdapter.removeOne(state, action.payload);
                    state.isLoading = false;
                }
            )
            .addCase(deleteMemberPayment.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

export const {
    selectAll: selectMemberPayments,
    selectById: selectMemberPaymentById,
} = memberPaymentAdapter.getSelectors<RootState>(
    (state) => state.memberPayments
);

export const {} = memberPaymentSlice.actions;

export const selectIsLoading = (state: RootState) =>
    state.memberPayments.isLoading;
export const selectMemberPaymentSummary = (state: RootState) =>
    state.memberPayments.summary;
export const selectCurrentPage = (state: RootState) =>
    state.memberPayments.currentPage;
export const selectTotalPages = (state: RootState) =>
    state.memberPayments.totalPages;
export const selectTotalRecords = (state: RootState) =>
    state.memberPayments.totalRecords;

export default memberPaymentSlice.reducer;
