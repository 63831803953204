import { Grid, FormControl } from "@mui/material";
import { PropsWithChildren } from "react";

const FormField = ({ children }: PropsWithChildren) => {
    return (
        <Grid item lg={6} md={6} sm={12} sx={{padding: '1rem', width: "100%"}}>
            <FormControl sx={{width: '100%'}}>
                {children}
            </FormControl>
        </Grid>
    );
}

export default FormField;