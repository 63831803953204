import axios from "axios";
import { configs } from "../app/app-configs";
import { Member, MemberSummary } from "./model/member.model";

export const getMembers = async (): Promise<Member[]> => {
    return await axios.get(`${configs.BASE_URL}/member`)
    .then(res => {
        return res.data as Member[];
    });
}

// export const getMemberPage = async (
//     search: MemberSearchCriteria
// ): Promise<PaginatedResponse<Member>> => {
//     return await axios
//         .get(
//             `${configs.BASE_URL}/member?page=${search.pagination.page}&size=${search.pagination.size}&name=${search.name}`
//         )
//         .then((res) => {
//             return res.data as PaginatedResponse<Member>;
//         });
// };

export const getMember = async (id: number): Promise<Member> => {
    return await axios.get(`${configs.BASE_URL}/member/${id}`)
    .then(res => {
        return res.data as Member;
    });
}

export const getMemberSummary = async (): Promise<MemberSummary> => {
    return await axios.get(`${configs.BASE_URL}/member/summary`)
    .then(res => {
        return res.data as MemberSummary;
    });
}

export const createMember = async (request: Member): Promise<Member> => {
    return await axios.post(`${configs.BASE_URL}/member`, request)
    .then(res => {
        return res.data as Member;
    });
}

export const updateMember = async (request: Member): Promise<Member> => {
    return await axios.put(`${configs.BASE_URL}/member/${request.id}`, request)
    .then(res => {
        return res.data as Member;
    });
}

export const deleteMember = async (id: number): Promise<number> => {
    return await axios.delete(`${configs.BASE_URL}/member/${id}`)
    .then(res => {
        return res.data;
    });
}