import {
    PayloadAction,
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import * as businessPaymentService from '../../service/business-payment.service';
import {
    BusinessPayment,
    BusinessPaymentSearchCriteria,
    BusinessPaymentSummary,
    getDefaultBusinessPaymentSearchCriteria,
} from '../../service/model/business-payment.model';
import { showNotification } from '../alerts/alert.slice';
import { NotificationType } from '../../shared/enums';
import { getAxiosErrorMessage } from '../../service/axios/axios.call.service';
import { PaginatedResponse } from '../../service/model/paginated-response';

export interface BusinessPaymentState {
    isLoading: boolean;
    payments: BusinessPayment[];
    summary?: BusinessPaymentSummary;
    page?: PaginatedResponse<BusinessPayment>;
    totalPages: number;
    currentPage: number;
    totalRecords: number;
}

const businessPaymentAdapter = createEntityAdapter({
    selectId: (payment: BusinessPayment) => payment.id,
    sortComparer: (a, b) => b.paymentDate.localeCompare(a.paymentDate),
});

export const initialState = businessPaymentAdapter.getInitialState({
    isLoading: false,
    payments: [],
    totalPages: 0,
    currentPage: 0,
    totalRecords: 0,
} as BusinessPaymentState);

export const fetchBusinessPaymentSummary =
    createAsyncThunk<BusinessPaymentSummary>(
        'businessPayments/summary',
        async (_, { rejectWithValue }) => {
            return businessPaymentService
                .getBusinessPaymentSummary()
                .catch((errors) => {
                    return rejectWithValue(errors);
                });
        }
    );

// export const fetchBusinessPayments = createAsyncThunk<BusinessPayment[]>(
//     'businessPayments/all',
//     async (_, {rejectWithValue}) => {
//         return businessPaymentService.getBusinessPayments().catch((errors) => {
//             return rejectWithValue(errors);
//         })
//     }
// );

export const fetchBusinessPaymentsPage = createAsyncThunk<
    PaginatedResponse<BusinessPayment>,
    BusinessPaymentSearchCriteria
>('businessPayments/page', async (pagination, { rejectWithValue }) => {
    return businessPaymentService
        .getBusinessPaymentsPage(pagination)
        .catch((errors) => {
            return rejectWithValue(errors);
        });
});

export const createBusinessPayment = createAsyncThunk<
    BusinessPayment,
    BusinessPayment
>('businessPayments/create', async (request, { rejectWithValue, dispatch }) => {
    return businessPaymentService
        .createBusinessPayment(request)
        .then((response) => {
            dispatch(
                showNotification({
                    message: 'BusinessPayment created successfully',
                    type: NotificationType.Success,
                })
            );
            // dispatch(fetchBusinessPayments())
            dispatch(
                fetchBusinessPaymentsPage(getDefaultBusinessPaymentSearchCriteria())
            );
            return response;
        })
        .catch((errors) => {
            dispatch(
                showNotification({
                    message: getAxiosErrorMessage(errors.response),
                    type: NotificationType.Error,
                })
            );
            return rejectWithValue(errors);
        });
});

export const updateBusinessPayment = createAsyncThunk<
    BusinessPayment,
    BusinessPayment
>('businessPayments/update', async (request, { rejectWithValue, dispatch }) => {
    return businessPaymentService
        .updateBusinessPayment(request)
        .then((response) => {
            dispatch(
                showNotification({
                    message: 'BusinessPayment updated successfully',
                    type: NotificationType.Success,
                })
            );
            dispatch(fetchBusinessPaymentSummary());
            return response;
        })
        .catch((errors) => {
            dispatch(
                showNotification({
                    message: getAxiosErrorMessage(errors.response),
                    type: NotificationType.Error,
                })
            );
            return rejectWithValue(errors);
        });
});

export const deleteBusinessPayment = createAsyncThunk<number, number>(
    'businessPayments/delete',
    async (request, { rejectWithValue, dispatch }) => {
        return businessPaymentService
            .deleteBusinessPayment(request)
            .then((response) => {
                dispatch(
                    showNotification({
                        message: 'BusinessPayment deleted successfully',
                        type: NotificationType.Success,
                    })
                );
                dispatch(fetchBusinessPaymentSummary());
                return response;
            })
            .catch((errors) => {
                dispatch(
                    showNotification({
                        message: getAxiosErrorMessage(errors.response),
                        type: NotificationType.Error,
                    })
                );
                return rejectWithValue(errors);
            });
    }
);

const businessPaymentSlice = createSlice({
    name: 'business-payment',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchBusinessPaymentSummary.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(
                fetchBusinessPaymentSummary.fulfilled,
                (state, action: PayloadAction<BusinessPaymentSummary>) => {
                    state.summary = action.payload;
                    state.isLoading = false;
                }
            )
            .addCase(fetchBusinessPaymentSummary.rejected, (state) => {
                state.isLoading = false;
            })
            // .addCase(fetchBusinessPayments.pending, (state) => {
            //     state.isLoading = true;
            // })
            // .addCase(fetchBusinessPayments.fulfilled, (state, action: PayloadAction<BusinessPayment[]>) => {
            //     businessPaymentAdapter.setAll(state, action.payload);
            //     state.isLoading = false;
            // })
            // .addCase(fetchBusinessPayments.rejected, (state) => {
            //     state.isLoading = false;
            // })
            .addCase(fetchBusinessPaymentsPage.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(
                fetchBusinessPaymentsPage.fulfilled,
                (
                    state,
                    action: PayloadAction<PaginatedResponse<BusinessPayment>>
                ) => {
                    businessPaymentAdapter.setAll(state, action.payload.data);
                    state.currentPage = action.payload.currentPage;
                    state.totalPages = action.payload.totalPages;
                    state.totalRecords = action.payload.totalRecords;
                    state.isLoading = false;
                }
            )
            .addCase(fetchBusinessPaymentsPage.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(createBusinessPayment.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(
                createBusinessPayment.fulfilled,
                (state, action: PayloadAction<BusinessPayment>) => {
                    businessPaymentAdapter.addOne(state, action.payload);
                    state.isLoading = false;
                }
            )
            .addCase(createBusinessPayment.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(updateBusinessPayment.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(
                updateBusinessPayment.fulfilled,
                (state, action: PayloadAction<BusinessPayment>) => {
                    businessPaymentAdapter.upsertOne(state, action.payload);
                    state.isLoading = false;
                }
            )
            .addCase(updateBusinessPayment.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(deleteBusinessPayment.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(
                deleteBusinessPayment.fulfilled,
                (state, action: PayloadAction<number>) => {
                    businessPaymentAdapter.removeOne(state, action.payload);
                    state.isLoading = false;
                }
            )
            .addCase(deleteBusinessPayment.rejected, (state) => {
                state.isLoading = false;
            });
    },
});

export const {
    selectAll: selectBusinessPayments,
    selectById: selectBusinessPaymentById,
} = businessPaymentAdapter.getSelectors<RootState>(
    (state) => state.businessPayments
);

export const {} = businessPaymentSlice.actions;

export const selectIsLoading = (state: RootState) =>
    state.businessPayments.isLoading;
export const selectBusinessPaymentSummary = (state: RootState) =>
    state.businessPayments.summary;
export const selectCurrentPage = (state: RootState) =>
    state.businessPayments.currentPage;
export const selectTotalPages = (state: RootState) =>
    state.businessPayments.totalPages;
export const selectTotalRecords = (state: RootState) =>
    state.businessPayments.totalRecords;

export default businessPaymentSlice.reducer;
