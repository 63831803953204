import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { fetchMemberships, selectMemberships } from "../features/memberships/membership.slice";

const useMemberships = () => {
    const dispatch = useAppDispatch();
    const memberships = useAppSelector(selectMemberships);

    const getMemberships = async () => {
        await dispatch(fetchMemberships());
        // await dispatch(fetchMembershipsPage(getDefaultMembershipSearchCriteria()));
    }

    useEffect(() => {
        if (memberships.length === 0) {
            getMemberships();
        }
    }, []);

    return [memberships];
}
 
export default useMemberships;