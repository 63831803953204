import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { fetchUsers, selectUsers } from "../features/user/user.slice";

const useUsers = () => {
    const dispatch = useAppDispatch();
    const users = useAppSelector(selectUsers);

    const getUsers = async () => {
        await dispatch(fetchUsers());
    }

    useEffect(() => {
        if (users.length === 0) {
            getUsers();
        }
    }, []);

    return [users];
}
 
export default useUsers;