import { Action, ThunkAction, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import rootReducer from "./root-reducer";

// export const store = configureStore({
//     reducer: rootReducer
// });

export const setupStore = (preloadedState?: Partial<RootState>) => {
    return configureStore({
        // middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        //     immutableCheck: {warnAfter: 256},
        //     serializableCheck: {warnAfter: 256}
        // }),
      reducer: rootReducer,
      preloadedState
    })
  }

export type RootState = ReturnType<typeof rootReducer>; 

export type AppStore = ReturnType<typeof setupStore>

export type AppDispath = AppStore['dispatch'];

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;