import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
    fetchMemberPaymentsPage,
    selectMemberPayments,
} from '../features/member-payments/member-payment.slice';
import { getDefaultMemberPaymentSearchCriteria } from '../service/model/member-payment.model';

const useBusinesess = () => {
    const dispatch = useAppDispatch();
    const payments = useAppSelector(selectMemberPayments);

    const getBusinesess = async () => {
        // await dispatch(fetchMemberPayments());
        await dispatch(
            fetchMemberPaymentsPage(getDefaultMemberPaymentSearchCriteria())
        );
    };

    useEffect(() => {
        if (payments.length === 0) {
            getBusinesess();
        }
    }, []);

    return [payments];
};

export default useBusinesess;
