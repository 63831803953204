import axios from 'axios';
import { configs } from '../app/app-configs';
import {
    MemberPayment,
    MemberPaymentSearchCriteria,
    MemberPaymentSummary,
} from './model/member-payment.model';
import { PaginatedResponse } from './model/paginated-response';

// export const getMemberPayments = async (): Promise<MemberPayment[]> => {
//     return await axios.get(`${configs.BASE_URL}/member-payment`)
//     .then(res => {
//         return res.data as MemberPayment[];
//     });
// }

export const getMemberPaymentsPage = async (
    search: MemberPaymentSearchCriteria
): Promise<PaginatedResponse<MemberPayment>> => {
    return await axios
        .get(
            `${configs.BASE_URL}/member-payment?page=${search.pagination.page}&size=${search.pagination.size}&name=${search.name}&from=${search.from}&to=${search.to}`
        )
        .then((res) => {
            return res.data as PaginatedResponse<MemberPayment>;
        });
};

export const createMemberPayment = async (
    request: MemberPayment
): Promise<MemberPayment> => {
    return await axios
        .post(`${configs.BASE_URL}/member-payment`, request)
        .then((res) => {
            return res.data as MemberPayment;
        });
};

export const updateMemberPayment = async (
    request: MemberPayment
): Promise<MemberPayment> => {
    return await axios
        .put(`${configs.BASE_URL}/member-payment/${request.id}`, request)
        .then((res) => {
            return res.data as MemberPayment;
        });
};

export const deleteMemberPayment = async (id: number): Promise<number> => {
    return await axios
        .delete(`${configs.BASE_URL}/member-payment/${id}`)
        .then((res) => {
            return res.data;
        });
};

export const getMemberPaymentSummary =
    async (): Promise<MemberPaymentSummary> => {
        return await axios
            .get(`${configs.BASE_URL}/member-payment/summary`)
            .then((res) => {
                return res.data as MemberPaymentSummary;
            });
    };
