import { Grid, Card, CardContent, Typography, CardMedia } from "@mui/material";
import { PropsWithChildren } from "react";


interface Props {    
    readonly title: string
    readonly description: string
    readonly image: string
}

function BenefitCard({title, description, image}: PropsWithChildren<Props>) {

    return <Grid item lg={3} md={6} sm={12} xs={12}>
        <Card sx={{ m: 2 }}>
          <CardMedia
            component="img"
            alt={title}
            height="50%"
            image={image}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {title}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {description}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
}

export default BenefitCard;