import { PayloadAction, createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import * as planService from '../../service/plan.service'
import { Plan } from "../../service/model/plan.model";
import { showNotification } from "../alerts/alert.slice";
import { NotificationType } from "../../shared/enums";

export interface PlanState {
    isLoading: boolean;
    plans: Plan[];
}

const plansAdapter = createEntityAdapter({
    selectId: (plan: Plan) => plan.id,
    sortComparer: (a, b) => a.name.localeCompare(b.name),
  })
  
export const initialState = plansAdapter.getInitialState({
    isLoading: false,
    plans: []
} as PlanState);

export const fetchPlans = createAsyncThunk<Plan[]>(
    'plans/all',
    async (_, {rejectWithValue}) => {
        return planService.getPlans().catch((errors) => {
            return rejectWithValue(errors);
        })
    }
);

export const createPlan = createAsyncThunk<Plan, Plan>(
    'plans/create',
    async (request, {rejectWithValue, dispatch}) => {
        return planService.createPlan(request)
        .then((response) => {
            dispatch(
                showNotification({
                    message: "Plan created successfully",
                    type: NotificationType.Success,
                })
            );
            return response;
        }).catch((errors) => {
            dispatch(
                showNotification({
                    message: "Plan creation failed",
                    type: NotificationType.Error,
                })
            );
            return rejectWithValue(errors);
        });
    }
);

export const updatePlan = createAsyncThunk<Plan, Plan>(
    'plans/update',
    async (request, {rejectWithValue, dispatch}) => {
        return planService.updatePlan(request)
        .then((response) => {
            dispatch(
                showNotification({
                    message: "Plan updated successfully",
                    type: NotificationType.Success,
                })
            );
            return response;
        }).catch((errors) => {
            dispatch(
                showNotification({
                    message: "Plan update failed",
                    type: NotificationType.Error,
                })
            );
            return rejectWithValue(errors);
        });
    }
);

export const deletePlan = createAsyncThunk<number, number>(
    'plans/delete',
    async (request, {rejectWithValue, dispatch}) => {
        return planService.deletePlan(request)
        .then((response) => {
            dispatch(
                showNotification({
                    message: "Plan deleted successfully",
                    type: NotificationType.Success,
                })
            );
            return response;
        }).catch((errors) => {
            dispatch(
                showNotification({
                    message: "Plan deletion failed",
                    type: NotificationType.Error,
                })
            );
            return rejectWithValue(errors);
        });
    }
);

const planSlice = createSlice({
    name: 'plans',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchPlans.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(fetchPlans.fulfilled, (state, action: PayloadAction<Plan[]>) => {
            plansAdapter.setAll(state, action.payload);
            state.isLoading = false;
        })
        .addCase(fetchPlans.rejected, (state) => {
            state.isLoading = false;
        })
        .addCase(createPlan.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(createPlan.fulfilled, (state, action: PayloadAction<Plan>) => {
            plansAdapter.addOne(state, action.payload);
            state.isLoading = false;
        })
        .addCase(createPlan.rejected, (state) => {
            state.isLoading = false;
        })
        .addCase(updatePlan.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(updatePlan.fulfilled, (state, action: PayloadAction<Plan>) => {
            plansAdapter.upsertOne(state, action.payload);
            state.isLoading = false;
        })
        .addCase(updatePlan.rejected, (state) => {
            state.isLoading = false;
        })
        .addCase(deletePlan.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(deletePlan.fulfilled, (state, action: PayloadAction<number>) => {
            plansAdapter.removeOne(state, action.payload);
            state.isLoading = false;
        })
        .addCase(deletePlan.rejected, (state) => {
            state.isLoading = false;
        });
    }
});

export const {
    selectAll: selectPlans,
    selectById: selectPlanById
} = plansAdapter.getSelectors<RootState>((state) => state.plans);

export const {} = planSlice.actions;

export const selectIsLoading = (state: RootState) => state.plans.isLoading;

export default planSlice.reducer;