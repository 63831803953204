import axios from 'axios';
import { configs } from '../app/app-configs';
import {
    BusinessPayment,
    BusinessPaymentSearchCriteria,
    BusinessPaymentSummary,
} from './model/business-payment.model';
import { PaginatedResponse } from './model/paginated-response';

// export const getBusinessPayments = async (): Promise<BusinessPayment[]> => {
//     return await axios.get(`${configs.BASE_URL}/business-payment`)
//     .then(res => {
//         return res.data as BusinessPayment[];
//     });
// }

export const getBusinessPaymentsPage = async (
    search: BusinessPaymentSearchCriteria
): Promise<PaginatedResponse<BusinessPayment>> => {
    return await axios
        .get(
            `${configs.BASE_URL}/business-payment?page=${search.pagination.page}&size=${search.pagination.size}&name=${search.name}&from=${search.from}&to=${search.to}`
        )
        .then((res) => {
            return res.data as PaginatedResponse<BusinessPayment>;
        });
};

export const createBusinessPayment = async (
    request: BusinessPayment
): Promise<BusinessPayment> => {
    return await axios
        .post(`${configs.BASE_URL}/business-payment`, request)
        .then((res) => {
            return res.data as BusinessPayment;
        });
};

export const updateBusinessPayment = async (
    request: BusinessPayment
): Promise<BusinessPayment> => {
    return await axios
        .put(`${configs.BASE_URL}/business-payment/${request.id}`, request)
        .then((res) => {
            return res.data as BusinessPayment;
        });
};

export const deleteBusinessPayment = async (id: number): Promise<number> => {
    return await axios
        .delete(`${configs.BASE_URL}/business-payment/${id}`)
        .then((res) => {
            return res.data;
        });
};

export const getBusinessPaymentSummary =
    async (): Promise<BusinessPaymentSummary> => {
        return await axios
            .get(`${configs.BASE_URL}/business-payment/summary`)
            .then((res) => {
                return res.data as BusinessPaymentSummary;
            });
    };
