import { Grid } from "@mui/material";
import StatCard from "../../components/StatCard";
import { StatSummary } from "../../components/StatSummary";
import useUserDashboardSummary from "../../hooks/use-user-dashboard-summary";
import ChartCard from "../../components/chart/chart-card";
import DoughnutChart from "../../components/chart/doughnut-chart";
import MembersIcon from '@mui/icons-material/People';
import AttendanceIcon from '@mui/icons-material/DirectionsWalk';
import MemberPaymentIcon from '@mui/icons-material/AttachMoney';
import SingleBarChart from "../../components/chart/single-bar-chart";

const UserDashboard = () => {
    const summary = useUserDashboardSummary();
    
    return (
        <>
            {summary &&
                <>
                    <StatSummary>
                        <StatCard title="Total Members" value={summary?.totalMembers} icon={MembersIcon} />
                        <StatCard title="Active Members" value={summary?.activeMembers} icon={MembersIcon} />
                        <StatCard title="Today Attendance" value={summary?.todayAttendance} icon={AttendanceIcon} />
                        <StatCard title="Received This Month" value={summary?.memberPaymentsThisMonth} icon={MemberPaymentIcon} />
                    </StatSummary>
                    <Grid container direction="row" justifyContent="flex-start">
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <ChartCard><SingleBarChart data={summary.dailyAttendanceTrend.map(item => { return { name: item.key, value: item.value } })} label='Daily Attendance' height={204} xAxisInterval={7}/></ChartCard>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <ChartCard><SingleBarChart data={summary.monthlyPaymentTrend.map(item => { return { name: item.key, value: item.value } })} label='Monthly Income' height={204} xAxisAngle={-90} /></ChartCard>
                        </Grid>
                        <Grid item lg={3} md={4} sm={12} xs={12}>
                            <ChartCard><DoughnutChart data={summary.countByStatus.map(item => { return { name: item.key, value: item.value } })} label='Member Status' /></ChartCard>
                        </Grid>
                        <Grid item lg={3} md={4} sm={12} xs={12}>
                            <ChartCard><DoughnutChart data={summary.countByMembership.map(item => { return { name: item.key, value: item.value } })} label='Membership' /></ChartCard>
                        </Grid>
                    </Grid>
                </>
            }
        </>
    )
}

export default UserDashboard;