import { Cell, Label, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

export interface DoughnutChartProps {
  data: {name: string, value: number}[],
  label?: string
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const DoughnutChart: React.FC<DoughnutChartProps> = ({data, label}) => {
  return (
    <ResponsiveContainer width={"99%"} height={250}>
      <PieChart margin={{ top: 5, right: 5, bottom: 5, left: 5 }}>
        <Tooltip />
        <Legend layout="horizontal" verticalAlign="bottom" align="center" />
        <Pie
          data={data}
          innerRadius={"70%"}
          outerRadius={"90%"}
          fill="#8884d8"
          paddingAngle={5}
          legendType="line"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
          <Label value={label} position="center" />
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}

export default DoughnutChart;