import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { fetchMemberPaymentSummary, selectMemberPaymentSummary } from "../features/member-payments/member-payment.slice";

const useMemberPaymentSummary = () => {
    const dispatch = useAppDispatch();
    const summary = useAppSelector(selectMemberPaymentSummary);

    const getMemberPaymentSummary = async () => {
        await dispatch(fetchMemberPaymentSummary());
    }

    useEffect(() => {
        getMemberPaymentSummary();
    }, []);

    return summary;
}
 
export default useMemberPaymentSummary;