import useAttendances from "../hooks/use-attendance";
import { makeStyles } from "@mui/styles";
import AttendanceForm from "../features/attendance/components/attendance-form";
import { useState } from "react";
import { Attendance as AttendanceModel } from "../service/model/attendance.model";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import * as attendanceSlice from "../features/attendance/attendance.slice";
import Loader from "../components/loader";
import AttendanceSummary from "../features/attendance/components/attendance-summary";
import AttendanceTable from "../features/attendance/components/attendance-table";

const useStyles = makeStyles({
    createButton: {
        float: 'inline-end',
        margin: '12px 0 4px 12px'
    }
})

const Attendance = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const [showAttendanceForm, setShowAttendanceForm] = useState(false);
    const isLoading = useAppSelector(attendanceSlice.selectIsLoading);

    const [searchText, setSearchText] = useState('');

    // const getAttendances = async () => {
    //     await dispatch(attendanceSlice.fetchAttendances());
    // }

    // useEffect(() => {
    //     if (attendances.length === 0) {
    //         getAttendances();
    //     }
    // }, []);

    const [attendances] = useAttendances();

    const handleSubmit = (data: AttendanceModel) => {
        dispatch(attendanceSlice.createAttendance(data));
        setShowAttendanceForm(false);
    };

    function setName(name: string) {
        setSearchText(name);
    }

    const getFilteredAttendance = () => {
        if(searchText === '') {
            return attendances;
        } 
        else {
            return attendances.filter(item => item.firstName.toLowerCase().includes(searchText.toLowerCase()));
        }
    }

    return (
        <div>
            {isLoading &&
                <Loader />
            }
            {attendances &&
                <>
                    <AttendanceSummary />
                    {/* <SearchBox placeholder="Search by name" setSearchValue={setName} /> */}
                    <AttendanceTable attendances={getFilteredAttendance()} />
                    {showAttendanceForm &&
                        <AttendanceForm attendance={undefined} editable={true} onClose={() => setShowAttendanceForm(false)} onSubmit={handleSubmit} />
                    }
                </>
            }
        </div>
    )
}

export default Attendance;