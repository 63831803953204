import { TableRow, Theme } from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";

export const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.info.light,
            },
            '&:hover': {
                backgroundColor: `${theme.palette.action.hover}`,
            }
        }
    }),
)(TableRow);
 
export default StyledTableRow;
