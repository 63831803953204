import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
    fetchBusinessPaymentsPage,
    selectBusinessPayments,
} from '../features/business-payments/business-payment.slice';
import { getDefaultBusinessPaymentSearchCriteria } from '../service/model/business-payment.model';

const useBusinesess = () => {
    const dispatch = useAppDispatch();
    const payments = useAppSelector(selectBusinessPayments);

    const getBusinesess = async () => {
        // await dispatch(fetchBusinessPayments());
        await dispatch(
            fetchBusinessPaymentsPage(getDefaultBusinessPaymentSearchCriteria())
        );
    };

    useEffect(() => {
        if (payments.length === 0) {
            getBusinesess();
        }
    }, []);

    return [payments];
};

export default useBusinesess;
