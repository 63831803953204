import { PropsWithChildren, ReactNode } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import dashboardImage from "../resources/images/features/data-analysis.jpg";
import paymentMgtImage from "../resources/images/features/payment.jpg";
import attendanceImage from "../resources/images/features/attendance.jpg";
import membersImage from "../resources/images/features/members.jpg";
import notificationImage from "../resources/images/features/notification.jpeg";
import techSupportImage from "../resources/images/features/techsupport.jpeg";
import staffImage from "../resources/images/features/staff.jpg";
import mobileAppImage from "../resources/images/features/mobile-app.jpeg";
import { Avatar, Card, CardContent, CardHeader, CardMedia, Grid, Theme } from "@mui/material";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PollIcon from '@mui/icons-material/Poll';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import EmailIcon from '@mui/icons-material/Mail';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import GroupIcon from '@mui/icons-material/Group';
import ComputerIcon from '@mui/icons-material/Computer';

const navBarHeight = 64;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
            root: {
                display: 'flex',
                // backgroundImage: `url(${mainBackground})`,
                minHeight: `calc(100vh - ${navBarHeight}px)`
                // backgroundImage: `url(${mainBackground})`,
                // height: '100%'
            },
            lightTextHeading: {
                color: `${theme.palette.primary.dark}`
            },
            lightTextDesc: {
                color: `${theme.palette.primary.dark}`,
                lineHeight: '1.5'
            },
            darkTextHeading: {
                color: `${theme.palette.secondary.light}`
            },
            darkTextDesc: {
                color: `${theme.palette.secondary.light}`,
                lineHeight: '1.5'
            },
            lightRow: {
                background: `${theme.palette.secondary.light}`,
                paddingTop: '15px',
                paddingBottom: '15px'
            },
            darkRow: {
                background: `${theme.palette.secondary.dark}`,
                paddingTop: '15px',
                paddingBottom: '15px'
            },
            cardRow: {
            },
            cardItem: {
              padding: '1rem',
            },
            card: {
                padding: '8px',
                // height: '100%',
                '& .MuiTypography-root': {
                    margin: 'auto'
                }
            },
            cardHeader: {
                '& .MuiTypography-root': {
                    fontSize: '18px'
                }
            },
            avatar: {
                backgroundColor: `${theme.palette.primary.dark}`,
                height: '48px',
                width: '48px'
            },
            cardContent: {
                // height: '50px',
                padding: '8px',
                '& .MuiTypography-root': {
                    fontSize: '15px'
                },
                '& .MuiCardContent-root': {
                    paddingBottom: '0'
                }
            },
            featureList: {
                color: 'grey',
                '& .MuiListItem-root': {
                    padding: 0
                }
            }
        }
    ))

interface FeatureCardProps {
    readonly title: string
    readonly image: string
    icon: ReactNode
}

function FeatureCard({title, image, icon, children}: PropsWithChildren<FeatureCardProps>) {
    const classes = useStyles();
    return  <Grid item lg={3} md={6} sm={12} xs={12} className={classes.cardItem}>
        <Card className={classes.card} elevation={2}>
            {/*<CardActionArea>*/}
            {/*    <CardMedia*/}
            {/*        component="img"*/}
            {/*        height="200px"*/}
            {/*        image={image}*/}
            {/*    />*/}
            <CardHeader
                className={classes.cardHeader}
                avatar={<Avatar className={classes.avatar}>{icon}</Avatar>}
                title={title}
            />
            <CardMedia
                component="img"
                height="224"
                image={image}
            />
            <CardContent className={classes.cardContent}>
                {children}
                {/*<Typography gutterBottom variant="body2" component="h2" color="textSecondary">*/}
                {/*    {description}*/}
                {/*</Typography>*/}
                {/*<Typography variant="body2" color="textSecondary" component="p">*/}
                {/*    {description}*/}
                {/*</Typography>*/}
            </CardContent>
            {/*</CardActionArea>*/}
        </Card>
    </Grid>
}

export default function Features() {
    const classes = useStyles();

    return <Grid container direction="row" className={classes.cardRow}>
        {/* <Grid container direction="row" justifyContent="start" alignItems="start"> */}
        <FeatureCard title="Powerful Analytical Dashboard"
            icon={<PollIcon />}
            image={dashboardImage}>
            {/* <Typography gutterBottom variant="body2" component="h2" color="textSecondary">
                        Treadmill analytical dashboard provides a detailed insights to the business operations
                    </Typography>
                    <List className={classes.featureList}>
                        <ListItem>
                            <ListItemIcon><ArrowRightIcon color="primary"/></ListItemIcon>
                            <ListItemText primary="Performance stat summaries" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><ArrowRightIcon color="primary"/></ListItemIcon>
                            <ListItemText primary="Graphical view of trends and patterns" />
                        </ListItem>
                    </List> */}
        </FeatureCard>

        <FeatureCard title="Member Management"
            icon={<EmojiPeopleIcon />}
            image={membersImage}>
            {/* <Typography gutterBottom variant="body2" component="h2" color="textSecondary">
                        Provide a detailed overview and control of your members. Register, update and remove members
                    </Typography>
                    <List className={classes.featureList}>
                        <ListItem>
                            <ListItemIcon><ArrowRightIcon color="primary"/></ListItemIcon>
                            <ListItemText primary="Create, view, update and remove members" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><ArrowRightIcon color="primary"/></ListItemIcon>
                            <ListItemText primary="Check member height, weight and BMI" />
                        </ListItem>
                    </List> */}
        </FeatureCard>
        <FeatureCard title="Payment Notifications"
            icon={<EmailIcon />}
            image={notificationImage}>
            {/* <Typography gutterBottom variant="body2" component="h2" color="textSecondary">
                        Notify your members about payment
                    </Typography>
                    <List className={classes.featureList}>
                        <ListItem>
                            <ListItemIcon><ArrowRightIcon color="primary"/></ListItemIcon>
                            <ListItemText primary="Notify upcoming payments and due payments" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><ArrowRightIcon color="primary"/></ListItemIcon>
                            <ListItemText primary="Send payments receipts" />
                        </ListItem>
                    </List> */}
        </FeatureCard>
        <FeatureCard title="Payment Management"
            icon={<AttachMoneyIcon />}
            image={paymentMgtImage}>
            {/* <Typography gutterBottom variant="body2" component="h2" color="textSecondary">
                        Easily track and manage your payments
                    </Typography>
                    <List className={classes.featureList}>
                        <ListItem>
                            <ListItemIcon><ArrowRightIcon color="primary"/></ListItemIcon>
                            <ListItemText primary="Record payments from members" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><ArrowRightIcon color="primary"/></ListItemIcon>
                            <ListItemText primary="Track monthly and yearly payments" />
                        </ListItem>
                    </List> */}
        </FeatureCard>
        <FeatureCard title="Attendance Tracking"
            icon={<EventAvailableIcon />}
            image={attendanceImage}>
            {/* <Typography gutterBottom variant="body2" component="h2" color="textSecondary">
                        Easily track and manage your members' attendance
                    </Typography>
                    <List className={classes.featureList}>
                        <ListItem>
                            <ListItemIcon><ArrowRightIcon color="primary"/></ListItemIcon>
                            <ListItemText primary="Record daily member attendance" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><ArrowRightIcon color="primary"/></ListItemIcon>
                            <ListItemText primary="See active and inactive members" />
                        </ListItem>
                    </List> */}
        </FeatureCard>
        <FeatureCard title="Technical Support"
            icon={<ComputerIcon />}
            image={techSupportImage}>
            {/* <Typography gutterBottom variant="body2" component="h2" color="textSecondary">
                        Reach us for any technical support
                    </Typography>
                    <List className={classes.featureList}>
                        <ListItem>
                            <ListItemIcon><ArrowRightIcon color="primary"/></ListItemIcon>
                            <ListItemText primary="Free lessons and guide to use system" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><ArrowRightIcon color="primary"/></ListItemIcon>
                            <ListItemText primary="Ready to help you when needed" />
                        </ListItem>
                    </List> */}
        </FeatureCard>
        <FeatureCard title="Staff Management"
            icon={<GroupIcon />}
            image={staffImage}>
            {/* <Typography gutterBottom variant="body2" component="h2" color="textSecondary">
                Track and manage your staff easily
            </Typography>
            <List className={classes.featureList}>
                <ListItem>
                    <ListItemIcon><ArrowRightIcon color="primary" /></ListItemIcon>
                    <ListItemText primary="Create, view, update and remove staff members" />
                </ListItem>
                <ListItem>
                    <ListItemIcon><ArrowRightIcon color="primary" /></ListItemIcon>
                    <ListItemText primary="Ready to help you when needed" />
                </ListItem>
            </List> */}
        </FeatureCard>
        <FeatureCard title="Mobile App"
            icon={<PhoneAndroidIcon />}
            image={mobileAppImage}>
            {/* <Typography gutterBottom variant="body2" component="h2" color="textSecondary">
                Mobile friendly application developed as a progressive web app
            </Typography>
            <List className={classes.featureList}>
                <ListItem>
                    <ListItemIcon><ArrowRightIcon color="primary" /></ListItemIcon>
                    <ListItemText primary="Use app with any mobile device" />
                </ListItem>
            </List> */}
        </FeatureCard>
        {/* </Grid> */}
    </Grid>
}