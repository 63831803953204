import { Card, CardContent, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { PropsWithChildren } from "react";

interface ChartCardProps {
    readonly title?: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            marginRight: "8px",
            marginTop: "12px",
        }
    }
    ));

export default function ChartCard({ title, children }: PropsWithChildren<ChartCardProps>) {
    const classes = useStyles();
    return <Card className={classes.card} elevation={1}>
        {/* <CardHeader>{title}</CardHeader> */}
        <CardContent>
            {children}
        </CardContent>
    </Card>
}