import { Grid } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";

interface DateRangePickerProps {
    initialStartDate: Date;
    initialEndDate: Date;
    onDateChange: (start: Date | null, end: Date | null) => void;
  }
  
  const DateRangeInput: React.FC<DateRangePickerProps> = ({ initialStartDate, initialEndDate, onDateChange }) => {
    const [startDate, setStartDate] = useState<Date | null>(initialStartDate);
    const [endDate, setEndDate] = useState<Date | null>(initialEndDate);

    const handleFromDateChange = (date: Dayjs | null) => {
          setStartDate(date?.toDate() ?? null);
          onDateChange(date?.toDate() ?? null, endDate)
      };

      const handleToDateChange = (date: Dayjs | null) => {
        setEndDate(date?.toDate() ?? null);
        onDateChange(startDate, date?.toDate() ?? null)
    };

    return (
        <>
            <Grid item lg={3} md={6} sm={12} sx={{ padding: '4px', width: "100%", marginTop: "8px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker 
                        label="From" 
                        className="w-100"
                        value={dayjs(initialStartDate)}
                        maxDate={dayjs(initialEndDate)}
                        onChange={handleFromDateChange}
                    />
                </LocalizationProvider>
                {/* <TextField
                    id="fromDate"
                    style={{ width: "100%" }}
                    label="From"
                    type="date"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        max: initialEndDate,
                      }}
                    defaultValue={initialStartDate ?? new Date().toISOString().slice(0, 10)}
                    
                /> */}
            </Grid>
            <Grid item lg={3} md={6} sm={12} sx={{ padding: '4px', width: "100%", marginTop: "8px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker 
                        label="To" 
                        className="w-100"
                        value={dayjs(initialEndDate)}
                        minDate={dayjs(initialStartDate)}
                        onChange={handleToDateChange}
                    />
                </LocalizationProvider>
                {/* <TextField
                    id="toDate"
                    style={{ width: "100%" }}
                    label="To"
                    type="date"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        min: initialStartDate,
                      }}
                    defaultValue={initialEndDate ?? new Date().toISOString().slice(0, 10)}
                /> */}
            </Grid>
        </>
    );
  }

export default DateRangeInput;


