import { TextField, TextFieldProps } from "@mui/material"
import { UseFormRegisterReturn } from "react-hook-form"

interface TextInputProps extends Omit<TextFieldProps, 'name' | 'onChange'> {
    readonly id?: string
    readonly name?: string
    readonly type?: string
    readonly className?: string
    readonly label?: string
    readonly defaultValue?: string | number
    readonly value?: string | number
    readonly min?: number
    readonly max?: number
    readonly disabled?: boolean
    readonly hidden?: boolean
    readonly variant?: "standard" | "filled" | "outlined"
    readonly error?: boolean
    readonly helperText?: string
    // readonly ref?: MutableRefObject<any>
    register: UseFormRegisterReturn;
    // onChange(value: any): any
}

export function TextInput({ name, label, register, error, variant='outlined', type, ...props }: TextInputProps) {
    const { name: registerName} = register;

    return <TextField
        id={props.id}
        {...register}
        name={registerName}
        label={label}
        type={type}
        className={props.className}
        defaultValue={props.defaultValue}
        variant={variant}
        value={props.value}
        disabled={props.disabled}
        hidden={props.hidden}
        error={error}
        helperText={props.helperText}
        // onChange={e => onChange(e)}
        // ref={ref}
        fullWidth
        InputLabelProps={{ shrink: true }}
        InputProps={{ inputProps: { min: props.min, max: props.max } }}
    />
}