import { Avatar, Box, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { resetState, selectLoggedInUser } from "../features/auth/auth.slice";
import React from "react";
import theme from "../app/app-theme";
import { useNavigate } from "react-router";

const ProfileMenu = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const loggedInUser = useAppSelector(selectLoggedInUser);

    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleChangePassword = () => {
        setAnchorElUser(null);
        navigate('/change-password');
    };

    const handleLogout = () => {
        localStorage.clear();
        dispatch(resetState());
        navigate('/');
        handleCloseUserMenu();
    }

    return (
        <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar sx={{ bgcolor: theme.palette.primary.light }}>{loggedInUser?.username[0]?.toUpperCase()}</Avatar>
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <MenuItem key={'profile'} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">Profile</Typography>
                </MenuItem>
                <MenuItem key={'changePassword'} onClick={handleChangePassword}>
                    <Typography textAlign="center">Change Password</Typography>
                </MenuItem>
                <MenuItem key={'logout'} onClick={handleLogout}>
                    <Typography textAlign="center">Logout</Typography>
                </MenuItem>
            </Menu>
        </Box>
    );
}

export default ProfileMenu;