import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import { hideNotification } from "./alert.slice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Alert from "@mui/material/Alert";

const NotificationBar = () => {
  const dispatch = useAppDispatch();
  const { open, message, type } = useAppSelector((state) => state.alerts);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    // if (reason === "clickaway") {
    //   return;
    // }

    dispatch(hideNotification());
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }} elevation={6} variant="filled">
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default NotificationBar;