import axios from "axios";
import { configs } from "../app/app-configs";
import { Plan } from "./model/plan.model";

export const getPlans = async (): Promise<Plan[]> => {
    return await axios.get(`${configs.BASE_URL}/plans`)
    .then(res => {
        return res.data as Plan[];
    });
}

export const createPlan = async (request: Plan): Promise<Plan> => {
    return await axios.post(`${configs.BASE_URL}/plans`, request)
    .then(res => {
        return res.data as Plan;
    });
}

export const updatePlan = async (request: Plan): Promise<Plan> => {
    return await axios.put(`${configs.BASE_URL}/plans/${request.id}`, request)
    .then(res => {
        return res.data as Plan;
    });
}

export const deletePlan = async (id: number): Promise<number> => {
    return await axios.delete(`${configs.BASE_URL}/plans/${id}`)
    .then(res => {
        return res.data;
    });
}