import { Box, Button } from "@mui/material";
import PlanTable from "../features/plans/components/plan-table";
import usePlans from "../hooks/use-plans";
import { makeStyles } from "@mui/styles";
import PlanForm from "../features/plans/components/plan-form";
import { useState } from "react";
import { Plan } from "../service/model/plan.model";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import * as planSlice from "../features/plans/plan.slice";
import Loader from "../components/loader";

const useStyles = makeStyles({
    createButton: {
        float: "inline-end",
        marginBottom: '1rem'
    }
})

const Plans = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const [showPlanForm, setShowPlanForm] = useState(false);
    const isLoading = useAppSelector(planSlice.selectIsLoading);

    // const getPlans = async () => {
    //     await dispatch(planSlice.fetchPlans());
    // }

    // useEffect(() => {
    //     if (plans.length === 0) {
    //         getPlans();
    //     }
    // }, []);

    const [plans] = usePlans();

    const handleSubmit = (data: Plan) => {
        dispatch(planSlice.createPlan(data));
        setShowPlanForm(false);
    };

    return (
        <div>
            {isLoading &&
                <Loader />
            }
            {plans &&
                <>
                    <Box>
                        <Button variant="contained" className={classes.createButton} onClick={() => setShowPlanForm(true)}>Create</Button>
                    </Box>
                    <PlanTable plans={plans} />
                    {showPlanForm &&
                        <PlanForm plan={undefined} editable={true} onClose={() => setShowPlanForm(false)} onSubmit={handleSubmit} />
                    }
                </>
            }
        </div>
    )
}

export default Plans;