export enum UserRoles {
    Admin = 'ROLE_ADMIN',
    User = 'ROLE_USER'
}

export enum NotificationType {
    Success = "success",
    Error = "error",
    Warning = "warning",
    Info = "info",
}

export enum StatCardIcon {
    USER = "USER",
    CALENDAR = "CALENDAR",
    HOUR_GLASS = "HOUR_GLASS",
    DUMBBELL = "DUMBBELL",
    STORE = "STORE",
    MEMBERSHIP = "MEMBERSHIP",
    DOLLER = "DOLLER",
    ATTEND = "ATTEND"
}