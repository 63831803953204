import axios from "axios";
import { configs } from "../app/app-configs";
import { User } from "./model/user.model";

export const getUsers = async (): Promise<User[]> => {
    return await axios.get(`${configs.BASE_URL}/user`)
    .then(res => {
        return res.data as User[];
    });
}

export const createUser = async (request: User): Promise<User> => {
    return await axios.post(`${configs.BASE_URL}/user`, request)
    .then(res => {
        return res.data as User;
    });
}

export const updateUser = async (request: User): Promise<User> => {
    return await axios.put(`${configs.BASE_URL}/user/${request.id}`, request)
    .then(res => {
        return res.data as User;
    });
}

export const deleteUser = async (id: number): Promise<number> => {
    return await axios.delete(`${configs.BASE_URL}/user/${id}`)
    .then(res => {
        return res.data;
    });
}