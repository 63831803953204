import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import * as dashboardService from '../../service/dashboard.service'
import { UserDashboardSummary } from "../../service/model/dashboard.model";

export interface UserDashboardState {
    isLoading: boolean;
    summary?: UserDashboardSummary;
}
  
export const initialState = ({
    isLoading: false,
} as UserDashboardState);

export const fetchUserDashboardSummary = createAsyncThunk<UserDashboardSummary>(
    'dashboard/user',
    async (_, {rejectWithValue}) => {
        return dashboardService.getUserDashboardSummary().catch((errors) => {
            return rejectWithValue(errors);
        })
    }
);

const userDashboardSlice = createSlice({
    name: 'userDashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchUserDashboardSummary.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(fetchUserDashboardSummary.fulfilled, (state, action: PayloadAction<UserDashboardSummary>) => {
            state.summary = action.payload;
            state.isLoading = false;
        })
        .addCase(fetchUserDashboardSummary.rejected, (state) => {
            state.isLoading = false;
        })
    }
});

export const {} = userDashboardSlice.actions;

export const selectIsLoading = (state: RootState) => state.userDashboard.isLoading;
export const selectUserDashboardSummary = (state: RootState) => state.userDashboard.summary;

export default userDashboardSlice.reducer;