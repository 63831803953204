import { addDays } from 'date-fns';
import { configs } from '../../app/app-configs';
import { KeyValuePair } from '../../model/key-value-pair';
import { Pagination } from '../../model/pagination';

export interface BusinessPayment {
    id: number;
    businessId: number;
    businessName: string;
    amount: number;
    paymentDate: string;
    fromDate: string;
}

export interface BusinessPaymentSummary {
    todayTotal: number;
    currentWeekTotal: number;
    currentMonthTotal: number;
}

export interface BusinessPaymentSearchCriteria {
    pagination: Pagination;
    name: string;
    from: string;
    to: string;
}

export const getDefaultBusinessPaymentSearchCriteria= (): BusinessPaymentSearchCriteria => {
    return {
        pagination: { page: 0, size: configs.PAGE_SIZE },
        name: '',
        from: addDays(new Date(), -7).toISOString().slice(0, 10),
        to: new Date().toISOString().slice(0, 10),
    }
}
