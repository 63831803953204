import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Membership } from "../../../service/model/membership.model";
import { Box, Button, Dialog, DialogTitle, Grid, IconButton, Modal, TextField } from "@mui/material";
import FormField from "../../../components/form-controls/form-field";
import CloseIcon from '@mui/icons-material/Close';
import theme from "../../../app/app-theme";

interface MembershipFormProps {
    readonly membership?: Membership
    readonly editable: boolean
    readonly onClose: () => void
    readonly onSubmit: (data: Membership) => void
}

const style = {
    // position: 'absolute' as 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // width: 400,
    // bgcolor: 'background.paper',
    // border: '2px solid #000',
    // boxShadow: 24,
    p: 2,
};

const MembershipForm: React.FC<MembershipFormProps> = ({ membership, editable, onClose, onSubmit }) => {
    const schema = yup.object().shape({
        name: yup.string()
            .required(),
        amount: yup.number()
            .transform((value) => Number.isNaN(value) ? null : value)
            .required("Amount is required")
            .min(0),
        durationDays: yup.number()
            .transform((value) => Number.isNaN(value) ? null : value)
            .required("Duration is required")
            .min(0)
    });

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmitHandler = (data: any) => {
        onSubmit(data as Membership)
    };

    return (
        <Dialog open={true} onClose={onClose} sx={style}>
            <DialogTitle>Membership Details</DialogTitle>
            <Box sx={style}>
                <>
                    <IconButton aria-label="close" onClick={onClose} sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}>
                        <CloseIcon />
                    </IconButton>
                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                        <FormField>
                            <TextField
                                id="membership-name"
                                label="Membership Name"
                                variant="outlined"
                                defaultValue={membership?.name}
                                error={errors.name !== undefined}
                                helperText={errors.name?.message}
                                {...register("name")}
                            />
                        </FormField>
                        <FormField>
                            <TextField
                                id="membership-amount"
                                type="number"
                                label="Amount"
                                variant="outlined"
                                defaultValue={membership?.amount}
                                error={errors.amount !== undefined}
                                helperText={errors.amount?.message}
                                {...register("amount")}
                            />
                        </FormField>
                        <FormField>
                            <TextField
                                id="duration"
                                type="number"
                                label="Duration (Days)"
                                variant="outlined"
                                defaultValue={membership?.durationDays}
                                error={errors.durationDays !== undefined}
                                helperText={errors.durationDays?.message}
                                {...register("durationDays")}
                            />
                        </FormField>
                        <Grid container direction="row" justifyContent='end' sx={{ mt: 1 }}>
                            <Button type="button" variant="contained" sx={{ mr: 1, bgcolor: `${theme.palette.secondary.main}` }} onClick={onClose}>Cancel</Button>
                            <Button type="submit" variant="contained" >Submit</Button>
                        </Grid>
                    </form>
                </>
            </Box>
        </Dialog>
    );
}

export default MembershipForm;