import { useState } from "react";
import StyledTable, { ColumnHeader } from "../../../components/table/styled-table";
import PlanTableRow from "./plan-table-row";
import { Plan } from "../../../service/model/plan.model";
import { configs } from "../../../app/app-configs";

const headers: ColumnHeader[] = [
    { name: "Id" },
    { name: "Name" },
    { name: "Price" },
    { name: "Actions", className: "action-header" }
]

interface TableProps {
    readonly plans: Plan[]
}

const PlanTable: React.FC<TableProps> = ({plans}) => {
    
    const [page, setPage] = useState(1);

    const pageCount = Math.ceil(plans.length / configs.PAGE_SIZE);
    
    const handlePageChange = (selectedPage: number) => {
        console.log('page changed: ', selectedPage);
        setPage(selectedPage);
    }

    return (
        <StyledTable headers={headers}
            currentPage={page}
            totalPages={pageCount}
            onPageChange={handlePageChange}>

            {plans?.map(plan =>
                <PlanTableRow plan={plan} key={plan.id} />
            )}
        </StyledTable>
    );
}

export default PlanTable;