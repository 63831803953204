import { PayloadAction, createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import * as userService from '../../service/user.service'
import { User } from "../../service/model/user.model";
import { showNotification } from "../alerts/alert.slice";
import { NotificationType } from "../../shared/enums";
import { getAxiosErrorMessage } from "../../service/axios/axios.call.service";
import { PaginatedResponse } from "../../service/model/paginated-response";

export interface UserState {
    isLoading: boolean;
    users: User[];
    page?: PaginatedResponse<User>;
    totalPages: number;
    currentPage: number;
    totalRecords: number;
}

const usersAdapter = createEntityAdapter({
    selectId: (user: User) => user.id,
    sortComparer: (a, b) => a.username?.localeCompare(b.username),
  })
  
export const initialState = usersAdapter.getInitialState({
    isLoading: false,
    users: [],
    totalPages: 0,
    currentPage: 0,
    totalRecords: 0,
} as UserState);

export const fetchUsers = createAsyncThunk<User[]>(
    'users/all',
    async (_, {rejectWithValue}) => {
        return userService.getUsers().catch((errors) => {
            return rejectWithValue(errors);
        })
    }
);

export const createUser = createAsyncThunk<User, User>(
    'users/create',
    async (request, {rejectWithValue, dispatch}) => {
        return userService.createUser(request)
        .then((response) => {
            dispatch(
                showNotification({
                    message: "User created successfully",
                    type: NotificationType.Success,
                })
            );
            // dispatch(
            //     fetchUsersPage(getDefaultUserSearchCriteria())
            // );
            return response;
        }).catch((errors) => {
            dispatch(
                showNotification({
                    message: getAxiosErrorMessage(errors.response),
                    type: NotificationType.Error,
                })
            );
            return rejectWithValue(errors);
        });
    }
);

export const updateUser = createAsyncThunk<User, User>(
    'users/update',
    async (request, {rejectWithValue, dispatch}) => {
        return userService.updateUser(request)
        .then((response) => {
            dispatch(
                showNotification({
                    message: "User updated successfully",
                    type: NotificationType.Success,
                })
            );
            return response;
        }).catch((errors) => {
            dispatch(
                showNotification({
                    message: getAxiosErrorMessage(errors.response),
                    type: NotificationType.Error,
                })
            );
            return rejectWithValue(errors);
        });
    }
);

export const deleteUser = createAsyncThunk<number, number>(
    'users/delete',
    async (request, {rejectWithValue, dispatch}) => {
        return userService.deleteUser(request)
        .then((response) => {
            dispatch(
                showNotification({
                    message: "User deleted successfully",
                    type: NotificationType.Success,
                })
            );
            return response;
        }).catch((errors) => {
            dispatch(
                showNotification({
                    message: getAxiosErrorMessage(errors.response),
                    type: NotificationType.Error,
                })
            );
            return rejectWithValue(errors);
        });
    }
);

const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchUsers.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(fetchUsers.fulfilled, (state, action: PayloadAction<User[]>) => {
            usersAdapter.setAll(state, action.payload);
            state.isLoading = false;
        })
        .addCase(fetchUsers.rejected, (state) => {
            state.isLoading = false;
        })
        .addCase(createUser.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(createUser.fulfilled, (state, action: PayloadAction<User>) => {
            usersAdapter.addOne(state, action.payload);
            state.isLoading = false;
        })
        .addCase(createUser.rejected, (state) => {
            state.isLoading = false;
        })
        .addCase(updateUser.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(updateUser.fulfilled, (state, action: PayloadAction<User>) => {
            usersAdapter.upsertOne(state, action.payload);
            state.isLoading = false;
        })
        .addCase(updateUser.rejected, (state) => {
            state.isLoading = false;
        })
        .addCase(deleteUser.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(deleteUser.fulfilled, (state, action: PayloadAction<number>) => {
            usersAdapter.removeOne(state, action.payload);
            state.isLoading = false;
        })
        .addCase(deleteUser.rejected, (state) => {
            state.isLoading = false;
        });
    }
});

export const {
    selectAll: selectUsers,
    selectById: selectUserById
} = usersAdapter.getSelectors<RootState>((state) => state.users);

export const {} = userSlice.actions;

export const selectIsLoading = (state: RootState) => state.users.isLoading;

export default userSlice.reducer;