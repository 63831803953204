import { useState } from "react";
import StyledTable, { ColumnHeader } from "../../../components/table/styled-table";
import BusinessTableRow from "./business-table-row";
import { Business } from "../../../service/model/business.model";
import { configs } from "../../../app/app-configs";

const headers: ColumnHeader[] = [
    { name: "Id" },
    { name: "Name" },
    { name: "Plan" },
    { name: "Status" },
    { name: "Actions", className: "action-header" }
]

interface TableProps {
    readonly businesses: Business[]
}

const BusinessTable: React.FC<TableProps> = ({businesses}) => {
    
    const [page, setPage] = useState(1);

    const pageCount = Math.ceil(businesses.length / configs.PAGE_SIZE);
    
    const handlePageChange = (selectedPage: number) => {
        setPage(selectedPage);
    }

    return (
        <StyledTable headers={headers}
            currentPage={page}
            totalPages={pageCount}
            onPageChange={handlePageChange}>

            {businesses?.map(business =>
                <BusinessTableRow business={business} key={business.id} />
            )}
        </StyledTable>
    );
}

export default BusinessTable;