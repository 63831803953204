import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { NotificationType } from "../../shared/enums";

export interface FeedbackAlert {
    open?: boolean,
    message: string,
    type?: NotificationType
}

export const initialState: FeedbackAlert = {
    open: false,
    message: "",
    type: NotificationType.Info
}

const alertSlice = createSlice({
    name: 'alerts',
    initialState,
    reducers: {
        showNotification: (state, action: PayloadAction<FeedbackAlert>) => {
            state.open = true;
            state.message = action.payload.message;
            state.type = action.payload.type;
          },
          hideNotification: (state) => {
            state.open = false;
            // state.message = "";
            // state.type = NotificationType.Info
          },
    },
    extraReducers: (builder) => { }
});

export const selectProducts = (state: RootState) => state.products.products;

export const {showNotification, hideNotification} = alertSlice.actions;

export default alertSlice.reducer;