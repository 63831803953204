import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import * as productService from '../../service/product.service'

export interface ProductState {
    products: string[]
}

export const initialState: ProductState = {
    products: []
}

export const getAll = createAsyncThunk<string[]>(
    'products/all',
    async (_, {rejectWithValue}) => {
        return productService.getProducts().catch((errors) => {
            return rejectWithValue(errors);
        })
    }
);

const productSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getAll.pending, (state) => {

        })
        .addCase(getAll.fulfilled, (state, action: PayloadAction<string[]>) => {
            state.products = action.payload;
        })
        .addCase(getAll.rejected, (state) => {
            
        });
    }
});

export const selectProducts = (state: RootState) => state.products.products;

export const {} = productSlice.actions;

export default productSlice.reducer;