import { configs } from '../../app/app-configs';
import { Pagination } from '../../model/pagination';
import { KeyValuePair } from '../../model/key-value-pair';

export interface MemberPayment {
    id: number;
    memberId: number;
    membershipId: number;
    firstName: string;
    lastName: string;
    amount: number;
    paymentDate: string;
    fromDate: string;
}

export interface MemberPaymentSummary {
    todayTotal: number;
    currentYearTotal: number;
    currentMonthTotal: number;
    lastMonthTotal: number;
    monthlyTotals: KeyValuePair<number>[];
    totalByMembership: KeyValuePair<number>[];
    topPaidMembers: KeyValuePair<number>[];
}

export interface MemberPaymentSearchCriteria {
    pagination: Pagination;
    name: string;
    from: string;
    to: string;
}

export const getDefaultMemberPaymentSearchCriteria =
    (): MemberPaymentSearchCriteria => {
        const startOfMonth = new Date();
        startOfMonth.setUTCDate(1);
        return {
            pagination: { page: 0, size: configs.PAGE_SIZE },
            name: '',
            from: startOfMonth.toISOString().slice(0, 10),
            to: new Date().toISOString().slice(0, 10),
        };
    };
