import { selectIsAuthenticated } from "../features/auth/auth.slice";
import { useAppSelector } from "../app/hooks";
import Login from "../pages/Login";

// @ts-ignore
export const PrivateRoute= ({ children }: PropsWithChildren<{}>) => {

    const isAuthenticated = useAppSelector(selectIsAuthenticated);
   
    return isAuthenticated ? children : <Login/>;

}

export default PrivateRoute; 