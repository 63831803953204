import Nav from "../Nav";
import { useAppSelector } from "../../app/hooks";
import { selectIsAuthenticated } from "../../features/auth/auth.slice";
import HomeNav from "../HomeNav";

const RootLayout = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  return (
    <>
      {isAuthenticated ? <HomeNav /> : <Nav />}
    </>
  );
};

export default RootLayout;