import MemberPaymentIcon from '@mui/icons-material/AttachMoney';
import useMemberPaymentSummary from "../../../hooks/use-member-payment-summary";
import StatCard from "../../../components/StatCard";
import { StatSummary } from "../../../components/StatSummary";
import { Card, CardContent, Grid, Typography } from '@mui/material';
import ChartCard from '../../../components/chart/chart-card';
import SingleBarChart from '../../../components/chart/single-bar-chart';
import DoughnutChart from '../../../components/chart/doughnut-chart';
import theme from "../../../app/app-theme";

const MemberPaymentSummary = () => {
    const paymentSummary = useMemberPaymentSummary();

    return (
        <div>
            {paymentSummary &&
                <>
                    <StatSummary>
                        <StatCard title="Today" value={paymentSummary?.todayTotal.toString()} icon={MemberPaymentIcon} />
                        <StatCard title="This Month" value={paymentSummary?.currentMonthTotal.toString()} icon={MemberPaymentIcon} />
                        <StatCard title="This Year" value={paymentSummary?.currentYearTotal.toString()} icon={MemberPaymentIcon} />
                        <StatCard title="Last Month" value={paymentSummary?.lastMonthTotal.toString()} icon={MemberPaymentIcon} />
                    </StatSummary>
                    <Grid container direction="row" justifyContent="flex-start">
                        <Grid item lg={5} md={4} sm={12} xs={12}>
                            <ChartCard><SingleBarChart data={paymentSummary.monthlyTotals.map(item => { return { name: item.key, value: item.value } })} label='Monthly Income' height={204} xAxisAngle={-90} /></ChartCard>
                        </Grid>
                        <Grid item lg={3} md={4} sm={12} xs={12}>
                            <ChartCard><DoughnutChart data={paymentSummary.totalByMembership.map(item => { return { name: item.key, value: item.value } })} label='Membership' /></ChartCard>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Card style={{ marginTop: "12px", marginRight: "8px", backgroundColor: theme.palette.primary.dark, color: theme.palette.primary.contrastText }}>
                                <CardContent style={{ padding: "10px" }}>
                                    <Typography variant="inherit">
                                        Top Members (Last 12 Months)
                                    </Typography>
                                </CardContent>
                            </Card>
                            <>
                                {paymentSummary?.topPaidMembers?.map(member =>
                                    <Card key={member.key} style={{ marginTop: "8px", marginRight: "8px", padding: "8px", backgroundColor: theme.palette.info.light }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={9}>
                                                <Typography variant="body1">{member.key}</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography variant="body1">{member.value}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                )}
                            </>
                        </Grid>
                    </Grid>
                </>
            }
        </div>
    )
}

export default MemberPaymentSummary;