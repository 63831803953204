import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { fetchMembers, selectMembers } from "../features/members/member.slice";

const useBusinesess = () => {
    const dispatch = useAppDispatch();
    const businesess = useAppSelector(selectMembers);

    const getBusinesess = async () => {
        // await dispatch(fetchMembersPage(getDefaultMemberSearchCriteria()));
        await dispatch(fetchMembers());
    }

    useEffect(() => {
        if (businesess.length === 0) {
            getBusinesess();
        }
    }, []);

    return [businesess];
}
 
export default useBusinesess;