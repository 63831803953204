import { PayloadAction, createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import * as memberService from '../../service/member.service'
import { Member, MemberSummary } from "../../service/model/member.model";
import { showNotification } from "../alerts/alert.slice";
import { NotificationType } from "../../shared/enums";
import { getAxiosErrorMessage } from "../../service/axios/axios.call.service";

export interface MemberState {
    isLoading: boolean;
    members: Member[];
    summary?: MemberSummary;
    // page?: PaginatedResponse<Member>;
    // totalPages: number;
    // currentPage: number;
    // totalRecords: number;
}

const memberAdapter = createEntityAdapter({
    selectId: (member: Member) => member.id,
    sortComparer: (a, b) => a.firstName?.localeCompare(b.firstName),
  })
  
export const initialState = memberAdapter.getInitialState({
    isLoading: false,
    members: [],
    totalPages: 0,
    currentPage: 0,
    totalRecords: 0,
} as MemberState);

export const fetchMemberSummary = createAsyncThunk<MemberSummary>(
    'members/summary',
    async (_, {rejectWithValue}) => {
        return memberService.getMemberSummary().catch((errors) => {
            return rejectWithValue(errors);
        })
    }
);

export const fetchMembers = createAsyncThunk<Member[]>(
    'members/all',
    async (_, {rejectWithValue}) => {
        return memberService.getMembers().catch((errors) => {
            return rejectWithValue(errors);
        })
    }
);

// export const fetchMembersPage = createAsyncThunk<
//     PaginatedResponse<Member>,
//     MemberSearchCriteria
// >('members/page', async (pagination, { rejectWithValue }) => {
//     return memberService
//         .getMemberPage(pagination)
//         .catch((errors) => {
//             return rejectWithValue(errors);
//         });
// });

export const createMember = createAsyncThunk<Member, Member>(
    'members/create',
    async (request, {rejectWithValue, dispatch}) => {
        return memberService.createMember(request)
        .then((response) => {
            dispatch(
                showNotification({
                    message: "Member created successfully",
                    type: NotificationType.Success,
                })
            );
            // dispatch(fetchMembers())
            dispatch(fetchMemberSummary())
            return response;
        }).catch((errors) => {
            dispatch(
                showNotification({
                    message: getAxiosErrorMessage(errors.response),
                    type: NotificationType.Error,
                })
            );
            return rejectWithValue(errors);
        });
    }
);

export const updateMember = createAsyncThunk<Member, Member>(
    'members/update',
    async (request, {rejectWithValue, dispatch}) => {
        return memberService.updateMember(request)
        .then((response) => {
            dispatch(
                showNotification({
                    message: "Member updated successfully",
                    type: NotificationType.Success,
                })
            );
            dispatch(fetchMemberSummary());
            return response;
        }).catch((errors) => {
            dispatch(
                showNotification({
                    message: getAxiosErrorMessage(errors.response),
                    type: NotificationType.Error,
                })
            );
            return rejectWithValue(errors);
        });
    }
);

export const deleteMember = createAsyncThunk<number, number>(
    'members/delete',
    async (request, {rejectWithValue, dispatch}) => {
        return memberService.deleteMember(request)
        .then((response) => {
            dispatch(
                showNotification({
                    message: "Member deleted successfully",
                    type: NotificationType.Success,
                })
            );
            dispatch(fetchMemberSummary());
            return response;
        }).catch((errors) => {
            dispatch(
                showNotification({
                    message: getAxiosErrorMessage(errors.response),
                    type: NotificationType.Error,
                })
            );
            return rejectWithValue(errors);
        });
    }
);

const memberSlice = createSlice({
    name: 'member',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchMemberSummary.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(fetchMemberSummary.fulfilled, (state, action: PayloadAction<MemberSummary>) => {
            state.summary = action.payload;
            state.isLoading = false;
        })
        .addCase(fetchMemberSummary.rejected, (state) => {
            state.isLoading = false;
        })
        .addCase(fetchMembers.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(fetchMembers.fulfilled, (state, action: PayloadAction<Member[]>) => {
            memberAdapter.setAll(state, action.payload);         
            // state.currentPage = action.payload.currentPage;
            // state.totalPages = action.payload.totalPages;
            // state.totalRecords = action.payload.totalRecords;
            state.isLoading = false;
        })
        .addCase(fetchMembers.rejected, (state) => {
            state.isLoading = false;
        })
        .addCase(createMember.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(createMember.fulfilled, (state, action: PayloadAction<Member>) => {
            memberAdapter.addOne(state, action.payload);
            state.isLoading = false;
        })
        .addCase(createMember.rejected, (state) => {
            state.isLoading = false;
        })
        .addCase(updateMember.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(updateMember.fulfilled, (state, action: PayloadAction<Member>) => {
            memberAdapter.upsertOne(state, action.payload);
            state.isLoading = false;
        })
        .addCase(updateMember.rejected, (state) => {
            state.isLoading = false;
        })
        .addCase(deleteMember.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(deleteMember.fulfilled, (state, action: PayloadAction<number>) => {
            memberAdapter.removeOne(state, action.payload);
            state.isLoading = false;
        })
        .addCase(deleteMember.rejected, (state) => {
            state.isLoading = false;
        });
    }
});

export const {
    selectAll: selectMembers,
    selectById: selectMemberById
} = memberAdapter.getSelectors<RootState>((state) => state.member);

export const {} = memberSlice.actions;

export const selectIsLoading = (state: RootState) => state.member.isLoading;
export const selectMemberSummary = (state: RootState) => state.member.summary;

export default memberSlice.reducer;