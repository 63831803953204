import axios from 'axios';
import { AuthRequest } from './model/user.model';
import { configs } from '../app/app-configs';
import {
    PasswordChangeRequest,
    PasswordResetLinkRequest,
    PasswordResetRequest,
} from './model/auth.model';

export const login = async (authRequest: AuthRequest) => {
    return await axios
        .post(`${configs.BASE_URL}/auth/login`, {
            username: authRequest.username,
            password: authRequest.password,
        })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const sendPasswordResetLink = async (
    request: PasswordResetLinkRequest
) => {
    return await axios
        .post(`${configs.BASE_URL}/auth/forgot-password`, request)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err;
        });
};

export const resetPassword = async (request: PasswordResetRequest) => {
    return await axios
        .post(`${configs.BASE_URL}/auth/reset-password`, request)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err;
        });
};

export const changePassword = async (request: PasswordChangeRequest) => {
    return await axios
        .post(`${configs.BASE_URL}/auth/change-password`, request)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err;
        });
};

export const getAuthUser = async () => {
    return await axios
        .get(`${configs.BASE_URL}/user/logged-in`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
