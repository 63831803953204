import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { fetchBusinessPaymentSummary, selectBusinessPaymentSummary } from "../features/business-payments/business-payment.slice";

const useBusinessPaymentSummary = () => {
    const dispatch = useAppDispatch();
    const summary = useAppSelector(selectBusinessPaymentSummary);

    const getBusinessPaymentSummary = async () => {
        await dispatch(fetchBusinessPaymentSummary());
    }

    useEffect(() => {
        getBusinessPaymentSummary();
    }, []);

    return summary;
}
 
export default useBusinessPaymentSummary;