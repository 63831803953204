import * as React from 'react';
import { createStyles, makeStyles } from "@mui/styles";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PlansIcon from '@mui/icons-material/CurrencyExchange';
import MembershipIcon from '@mui/icons-material/CardMembership';
import MemberIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import AttendanceIcon from '@mui/icons-material/DirectionsWalk';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import UsersIcon from '@mui/icons-material/SupervisedUserCircle';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ProfileMenu from './profile-menu';
import { useAppSelector } from '../app/hooks';
import { selectPermissions } from '../features/auth/auth.slice';
import { UserRoles } from '../shared/enums';
import { configs } from '../app/app-configs';
import { MenuItem, Theme } from '@mui/material';

const drawerWidth = 240;

enum SideBarItem {
  DASHBOARD, PLANS, MEMBERS, ATTENDANCE, BUSINESS, MEMBERSHIPS, MEMBER_PAYMENTS, BUSINESS_PAYMENTS, USERS
}

enum SideBarItemUrl {
  DASHBOARD = "/dashboard",
  PLANS = "/plans",
  MEMBERS = "/members",
  ATTENDANCE = "/attendance",
  BUSINESS = "/business",
  MEMBERSHIPS = "/memberships",
  MEMBER_PAYMENTS = "/member-payments",
  BUSINESS_PAYMENTS = "/business-payments",
  USERS = "/users"
}

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window?: () => Window;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
      menu: {
          padding: 0,
          '& .MuiMenuItem-root': {
            paddingTop: "12px",
            paddingBottom: "12px",
          },
          '& .MuiListItemIcon-root': {
            marginRight: "20px",
          },
          '& .Mui-selected': {
            backgroundColor: theme.palette.primary.light
          },
      }
  }
  ))

export default function HomeNav(props: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const permissions = useAppSelector(selectPermissions);

  const getSidebarItemFromPath  = () : SideBarItem => {
    switch(location.pathname) {
      case SideBarItemUrl.DASHBOARD:
        return SideBarItem.DASHBOARD;
      case SideBarItemUrl.PLANS:
        return SideBarItem.PLANS;
      case SideBarItemUrl.MEMBERS:
        return SideBarItem.MEMBERS;
      case SideBarItemUrl.ATTENDANCE:
        return SideBarItem.ATTENDANCE;
      case SideBarItemUrl.BUSINESS:
        return SideBarItem.BUSINESS;
      case SideBarItemUrl.MEMBERSHIPS:
        return SideBarItem.MEMBERSHIPS;
      case SideBarItemUrl.MEMBER_PAYMENTS:
        return SideBarItem.MEMBER_PAYMENTS;
      case SideBarItemUrl.BUSINESS_PAYMENTS:
        return SideBarItem.BUSINESS_PAYMENTS;
      case SideBarItemUrl.USERS:
        return SideBarItem.USERS;
      default:
        return SideBarItem.DASHBOARD;
    }
  };
  const [selectedItem, setSelectedItem] = React.useState(getSidebarItemFromPath())
  
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onSelectItem = (item: SideBarItem, url: string) => {
    setSelectedItem(item)
    navigate(url)
  }

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List className={classes.menu}>
        <MenuItem onClick={() => onSelectItem(SideBarItem.DASHBOARD, "/dashboard")} sx={{ cursor: 'pointer' }} selected={selectedItem === SideBarItem.DASHBOARD}>
          <ListItemIcon><DashboardIcon /></ListItemIcon>
          <ListItemText primary={"Dashboard"} />
        </MenuItem>
        {permissions.includes(UserRoles.Admin) &&
          <>
            <MenuItem onClick={() => onSelectItem(SideBarItem.PLANS, "/plans")} sx={{ cursor: 'pointer' }} selected={selectedItem === SideBarItem.PLANS}>
              <ListItemIcon><PlansIcon /></ListItemIcon>
              <ListItemText primary={"Plans"} />
            </MenuItem>
            <MenuItem onClick={() => onSelectItem(SideBarItem.BUSINESS, "/business")} sx={{ cursor: 'pointer' }} selected={selectedItem === SideBarItem.BUSINESS}>
              <ListItemIcon><BusinessIcon /></ListItemIcon>
              <ListItemText primary={"Business"} />
            </MenuItem>
            <MenuItem onClick={() => onSelectItem(SideBarItem.BUSINESS_PAYMENTS, "/business-payments")} sx={{ cursor: 'pointer' }} selected={selectedItem === SideBarItem.BUSINESS_PAYMENTS}>
              <ListItemIcon><MoneyIcon /></ListItemIcon>
              <ListItemText primary={"Received"} />
            </MenuItem>
          </>
        }
        {permissions.includes(UserRoles.User) &&
          <>
            <MenuItem onClick={() => onSelectItem(SideBarItem.MEMBERSHIPS, "/memberships")} sx={{ cursor: 'pointer' }} selected={selectedItem === SideBarItem.MEMBERSHIPS}>
              <ListItemIcon><MembershipIcon /></ListItemIcon>
              <ListItemText primary={"Memberships"} />
            </MenuItem>
            <MenuItem onClick={() => onSelectItem(SideBarItem.MEMBERS, "/members")} sx={{ cursor: 'pointer' }} selected={selectedItem === SideBarItem.MEMBERS}>
              <ListItemIcon><MemberIcon /></ListItemIcon>
              <ListItemText primary={"Members"} />
            </MenuItem>
            <MenuItem onClick={() => onSelectItem(SideBarItem.ATTENDANCE, "/attendance")} sx={{ cursor: 'pointer' }} selected={selectedItem === SideBarItem.ATTENDANCE}>
              <ListItemIcon><AttendanceIcon /></ListItemIcon>
              <ListItemText primary={"Attendance"} />
            </MenuItem>
            <MenuItem onClick={() => onSelectItem(SideBarItem.MEMBER_PAYMENTS, "/member-payments")} sx={{ cursor: 'pointer' }} selected={selectedItem === SideBarItem.MEMBER_PAYMENTS}>
              <ListItemIcon><MoneyIcon /></ListItemIcon>
              <ListItemText primary={"Received"} />
            </MenuItem>
          </>
        }
        <MenuItem onClick={() => onSelectItem(SideBarItem.USERS, "/users")} sx={{ cursor: 'pointer' }} selected={selectedItem === SideBarItem.USERS}>
          <ListItemIcon><UsersIcon /></ListItemIcon>
          <ListItemText primary={"Users"} />
        </MenuItem>
      </List>
    </div>
  );

  // Remove this const when copying and pasting into your project.
  // const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {configs.APP_NAME}
          </Typography>
          <ProfileMenu />
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onClick={handleDrawerToggle}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` }, backgroundColor: (theme) => theme.palette.background.default }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}
