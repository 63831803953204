import { useEffect, useState } from "react";
import * as authSlice from '../features/auth/auth.slice';
import { useAppDispatch } from "../app/hooks";
import { Avatar, Box, Button, Container, TextField, Typography } from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { REGES_EMAIL } from "../shared/constants";
import Copyright from "../components/copyright";
import { PasswordResetLinkRequest } from "../service/model/auth.model";

const ForgotPassword = () => {
    const dispatch = useAppDispatch();

    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true);

    useEffect(() => {
        dispatch(authSlice.resetState());
    }, []);
    
    const resetPassword = async () => {
        const requestPasswordResetEmail = async () => {
            const request: PasswordResetLinkRequest = { email: email ?? ''}
            await dispatch(authSlice.sendPasswordResetEmail(request))
        }

        await requestPasswordResetEmail();
    }

    function handleEmailChange(value: string): void {
        const emailRegex = new RegExp(REGES_EMAIL); 
        setIsValidEmail(emailRegex.test(value));
        setEmail(value);
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5" sx={{m: 3}}>
                Reset your password
                </Typography>
                <Box sx={{ mt: 1 }}>
                    <Typography>Enter your user account's verified email address and we will send you a password reset link.</Typography>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        type="email"
                        value={email}
                        placeholder="Enter your email address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={e => handleEmailChange(e.target.value)}
                        error={!isValidEmail}
                        helperText={isValidEmail ? "" : "Invalid email format"}
                    />  
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={resetPassword}
                        disabled={!isValidEmail}
                    >
                        Send password reset email
                    </Button>                    
                </Box>
            </Box>
            <Box sx={{ mt: 8, mb: 4 }}>
                <Copyright/>
            </Box>
        </Container>
    );
}

export default ForgotPassword;