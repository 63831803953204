import { useState } from "react";
import StyledTable, { ColumnHeader } from "../../../components/table/styled-table";
import UserTableRow from "./user-table-row";
import { User } from "../../../service/model/user.model";
import { configs } from "../../../app/app-configs";

const headers: ColumnHeader[] = [
    { name: "Username" },
    { name: "First Name" },
    { name: "Last Name" },
    { name: "Contact" },
    { name: "Actions", className: "action-header" }
]

interface TableProps {
    readonly users: User[]
}

const UserTable: React.FC<TableProps> = ({users}) => {
    
    const [page, setPage] = useState(1);

    const pageCount = Math.ceil(users.length / configs.PAGE_SIZE);
    
    const handlePageChange = (selectedPage: number) => {
        setPage(selectedPage);
    }

    return (
        <StyledTable headers={headers}
            currentPage={page}
            totalPages={pageCount}
            onPageChange={handlePageChange}>

            {users?.map(user =>
                <UserTableRow user={user} key={user.id} />
            )}
        </StyledTable>
    );
}

export default UserTable;