import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { fetchAttendanceSummary, selectAttendanceSummary } from "../features/attendance/attendance.slice";

const useAttendanceSummary = () => {
    const dispatch = useAppDispatch();
    const summary = useAppSelector(selectAttendanceSummary);

    const getAttendanceSummary = async () => {
        await dispatch(fetchAttendanceSummary());
    }

    useEffect(() => {
        getAttendanceSummary();
    }, []);

    return summary;
}
 
export default useAttendanceSummary;