import { Button, CardMedia, Grid, Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { useEffect } from "react";
import { useAppDispatch } from "../app/hooks";
import { setIsAuthenticated } from "../features/auth/auth.slice";
import { useNavigate } from "react-router-dom";
import notificationImage from "../resources/images/home/notification.jpg";
import growthImage from "../resources/images/home/growth.jpg";
import allInOneImage from "../resources/images/home/all-in-one.jpg";
import decisionMakingImage from "../resources/images/home/decision-making.jpg";
import memberDataImage from "../resources/images/home/member-data.jpg";
import theme from "../app/app-theme";
import Footer from "../components/Footer";
import Hero from "../components/hero";
import BenefitCard from "../components/benefit-card";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: `${theme.palette.secondary.light}`,
    },
    dashboard: {
        marginTop: '2rem',
    },
    sideBySideRow: {
      marginTop: '12px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '2rem',
            marginBottom: '2rem',
        }
    },
    checkPlans: {
        backgroundColor: 'navy'
    },
    checkFeaturesButton: {
        justify: 'space-around',
        border: ` ${theme.palette.primary.main} 1px solid`,
        borderRadius: '40px',
        fontSize: 'large',
        backgroundColor: ` ${theme.palette.primary.main} !important`,
        color: `${theme.palette.secondary.light} !important`,
        padding: '22px 48px',
        '& .MuiTypography-root': {
            textAlign: 'center'
        },
        '&:hover': {
            color: `${theme.palette.primary.main} !important`,
            background: `${theme.palette.secondary.light} !important`
        }
    },
    checkPlanButton: {
        justify: 'space-around',
        border: ` ${theme.palette.primary.main} 1px solid`,
        borderRadius: '40px',
        fontSize: 'large',
        color: `${theme.palette.primary.dark} !important`,
            background: `${theme.palette.secondary.light} !important`,
        padding: '22px 48px',
        '& .MuiTypography-root': {
            textAlign: 'center'
        },
        '&:hover': {
            color: `${theme.palette.secondary.light} !important`,
            background: `${theme.palette.primary.dark}!important`,
            border: `1px solid !important`
        }
    },
  }
  ));
  

const Home = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
  const classes = useStyles();

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      dispatch(setIsAuthenticated(true));
      navigate('/dashboard');
    } else {
      dispatch(setIsAuthenticated(false));
    }
  })

  

  return ( <div className={classes.root}>
    <div>
    <Hero/>
    </div>
    {/* <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.dashboard}>
      <Grid item lg={12}>
        <div style={{marginRight: "4px", marginLeft: "4px", textAlign: "center"}}>
          <h1>Boost your productivity</h1>
          <h3>Save your time and effort with easy-to-use powerful dashboard</h3>
        </div>
      </Grid>
      <Grid item lg={8} md={6} sm={12} style={{ marginTop: '2rem' }}>
        <div>
          <CardMedia
            component="img"
            image={dashboardImage}
            height="50%%"
          />
        </div>
      </Grid>
    </Grid> */}

    <Grid container direction="row" justifyContent="center" alignItems="center" style={{backgroundColor: "teal"}}>
      <BenefitCard 
        title="Retain your members"
        description="Don't let you members quit. Keep them coming with frequent notifications"
        image={notificationImage}
      />
      <BenefitCard 
        title="Track your growth"
        description="Follow how your fitness center perform over a period of time"
        image={growthImage}
      />
      <BenefitCard 
        title="Make informed decisions"
        description="Power-up your decision making and grow your business"
        image={decisionMakingImage}
      />
      <BenefitCard 
        title="Track your members"
        description="Have better understaing about your members and their activities"
        image={memberDataImage}
      />

    </Grid>
       
    <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.sideBySideRow}>
        <Grid item lg={6} md={6}>
          <div style={{marginRight: "4px", marginLeft: "4px", textAlign: "center"}}>
            <h1>All-in-one feature-rich application</h1>
            <h3>Enjoy vast range of features tailor-made for managing fitness centers</h3>
          </div>
          <div style={{ marginTop: '2rem', textAlign: "center" }}>
            <Button className={`${classes.checkFeaturesButton} btn`} onClick={() => navigate("/features")}>Check all features</Button>
          </div>
        </Grid>
      <Grid item lg={4} md={4}>
        <div>
          <CardMedia
            component="img"
            image={allInOneImage}
            height="50%%"
          />
        </div>
      </Grid>
    </Grid>
    <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.checkPlans}>
      <Grid item lg={12}>
        <div style={{marginRight: "4px", marginLeft: "4px", textAlign: "center", color: `${theme.palette.secondary.contrastText}`}}>
          <h2>Select the best suited plan for your business</h2>
        </div>
        <div style={{ marginBottom: '1rem', textAlign: "center" }}>
          <Button variant="contained" className={classes.checkPlanButton}  onClick={() => navigate("/pricing")}>Check Plans</Button>
        </div>
      </Grid>
    </Grid>
    <Footer/>
  </div>
  )


};

export default Home;